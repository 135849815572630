import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { appStore } from '../app.store';
import { Store } from '@ngrx/store';
import { filter, map, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[hasPermissions]',
  standalone: true,
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input() hasPermissions: string[];

  private sub: Subscription;

  constructor(private el: ElementRef, private store: Store<appStore>) {}

  ngOnInit(): void {
    this.el.nativeElement.style.display = 'none';

    this.sub = this.store
      .select((state) => state.auth)
      .pipe(
        filter((auth) => auth.authLoaded),
        map(
          (auth) =>
            this.hasPermissions.filter((perm) =>
              auth.permissions.includes(perm)
            ).length == this.hasPermissions.length
        )
      )
      .subscribe((authorized) => {
        console.log('authorized', authorized);
        this.el.nativeElement.style.display = authorized ? 'block' : 'none';
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
