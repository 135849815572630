<div class="row justify-content-end pt-2">
  <div class="col-3 pt-2">
    <a (click)="decrementCount()">
      <fa-icon [icon]="faMinus" [fixedWidth]="true" pull="right"></fa-icon>
    </a>
  </div>
  <div class="col-2 text-center">
    {{ (product | async)!.count }}
  </div>
  <div class="col-3 pt-2">
    <a (click)="incrementCount()">
      <fa-icon [icon]="faPlus" [fixedWidth]="true" pull="left"></fa-icon>
    </a>
  </div>
</div>
