import { Component, Input, OnInit } from '@angular/core';
import { PaymentProvider } from '../../../store/order.types';
import { appStore } from '../../../../../app.store';
import { Store } from '@ngrx/store';
import { OrderActions } from '../../../store/order.action';

@Component({
  selector: 'app-bank-transfer',
  standalone: true,
  imports: [],
  templateUrl: './bank-transfer.component.html',
  styleUrl: './bank-transfer.component.scss',
})
export class BankTransferComponent implements OnInit {
  @Input()
  paymentProvider: PaymentProvider;

  ngOnInit(): void {
    this.store.dispatch(
      OrderActions.configurePaymentDetails({ cost: 0, payload: {} })
    );
  }

  constructor(private store: Store<appStore>) {}
}
