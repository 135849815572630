import { createReducer, on } from '@ngrx/store';
import { Alert } from './common.types';
import { CommonAction } from './common.action';

export interface commonModuleState {
  alerts: Alert[];
}

export const initialState: commonModuleState = {
  alerts: [],
};

export const commonReducer = createReducer(
  initialState,
  on(CommonAction.createAlert, (state, { alert }) => {
    return {
      ...state,
      alerts: [...state.alerts, alert],
    };
  }),
  on(CommonAction.closeAlert, (state, { id }) => {
    return {
      ...state,
      alerts: state.alerts.filter((e) => e.id !== id),
    };
  })
);
