import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from './store/product.types';
import { environment } from '../../../environments/environment';
import { getBackendUrl } from '../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private httpClient: HttpClient) {}

  listProducts() {
    return this.httpClient.get<Product[]>(
      `${getBackendUrl(environment.backendUrl)}/products`,
      {
        withCredentials: true,
      }
    );
  }
}
