<div *ngIf="initialized | async">
  <div *ngIf="!(deliverySettings | async)?.payload">
    <button class="btn btn-secondary" (click)="openPacketaWidget()" i18n>
      Select a Pick up Point
    </button>
  </div>

  <div *ngIf="(deliverySettings | async)?.payload">
    <div class="row">
      <h2 i18n>Selected Pick Up Point</h2>

      <p>
        {{ (deliverySettings | async)?.payload.place }}
      </p>
      <p>
        {{ (deliverySettings | async)?.payload.name }}
      </p>
    </div>

    <div class="row">
      <div
        *ngFor="let photo of firstThreeImages | async"
        class="col-xs-12 col-sm-4 col-md-4 p-3"
      >
        <img [src]="photo" class="img-fluid" />
      </div>
    </div>

    <button class="btn btn-secondary" (click)="openPacketaWidget()" i18n>
      Change Pick up Point
    </button>
  </div>
</div>
