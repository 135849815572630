import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBackendUrl } from '../common/helper/backend.helper';
import { environment } from '../../environments/environment';
import { AnonSessionStore } from './store/auth.reducer';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(public httpClient: HttpClient) {}

  getPermissions() {
    return this.httpClient.get<string[]>(
      `${getBackendUrl(environment.backendUrl)}/profile/permissions`,
      { withCredentials: true }
    );
  }

  getAnonState() {
    return this.httpClient.get<AnonSessionStore>(
      `${getBackendUrl(environment.backendUrl)}/profile/anon`,
      { withCredentials: true }
    );
  }

  destroyAnonSession() {
    return this.httpClient.delete(
      `${getBackendUrl(environment.backendUrl)}/profile/anon`,
      {
        withCredentials: true,
      }
    );
  }
}
