import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { Concept } from '../store/concept.types';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConceptResultListComponent } from '../concept-result-list/concept-result-list.component';
import { Store } from '@ngrx/store';
import { appStore } from '../../app.store';
import { ConceptAction } from '../store/concepts.action';

@Component({
  selector: 'app-concept',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FontAwesomeModule, ConceptResultListComponent],
  templateUrl: './concept.component.html',
  styleUrl: './concept.component.scss',
})
export class ConceptComponent {
  constructor(private modalService: NgbModal, private store: Store<appStore>) {}

  @Input() concept: Concept;

  @Input() adminContext: boolean = false;

  loaded: boolean = false;
  faSpinner = faSpinner;

  onLoad() {
    this.loaded = true;
  }

  open() {
    const modalRef = this.modalService.open(ConceptResultListComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.conceptResults$ = this.store.select((state) =>
      this.adminContext
        ? state.adminConcept.concepts.find((e) => e.id == this.concept.id)
            .concept_results
        : state.conceptModule.concepts.find((e) => e.id == this.concept.id)
            .concept_results
    );
  }

  select() {
    this.store.dispatch(ConceptAction.selectConcept({ concept: this.concept }));
  }
}
