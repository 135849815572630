import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { appStore } from '../../../../../app.store';
import { Observable, Subject, switchMap, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AddressCardComponent } from '../../../common/address-card/address-card.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAddressComponent } from '../../../common/add-address/add-address.component';
import { DeliveryProvider, OrderAddress } from '../../../store/order.types';
import { OrderActions } from '../../../store/order.action';

@Component({
  selector: 'app-packeta-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AddressCardComponent],
  templateUrl: './packeta-address.component.html',
  styleUrl: './packeta-address.component.scss',
})
export class PacketaAddressComponent implements OnInit {
  @Input()
  deliveryProvider: DeliveryProvider;

  shippingAddress: Observable<OrderAddress | undefined>;

  modifyStr = $localize`:Modify (Billing Address):Modify`;
  setStr = $localize`:Set (Billing Address):Set`;

  // Source for incoming from form
  formAddressChanges: Subject<OrderAddress> = new Subject();

  isSameBillingAddress: FormControl = new FormControl<boolean>(false);

  private modalService = inject(NgbModal);

  billingAddressSelector = createSelector(
    (state: appStore) => state.eshopOrderModule.draft,
    (st) => st?.billing_address
  );

  getShippingPrice(address: OrderAddress | undefined) {
    if (!address) {
      return 0;
    }
    const country = this.deliveryProvider.delivery_country_price.find(
      (e) => address.country.id == e.country.id
    );
    if (!country) {
      return 0;
    } else {
      return country.cost;
    }
  }

  constructor(private store: Store<appStore>) {}

  setShippingAddress() {
    this.isSameBillingAddress.setValue(false);

    const componentRef = this.modalService.open(AddAddressComponent, {
      size: 'lg',
    });

    (componentRef.componentInstance.formFilled as Subject<OrderAddress>)
      .pipe(take(1))
      .subscribe((payload) => {
        this.formAddressChanges.next(payload);
      });
    componentRef.componentInstance.formType = 'shipping';
  }

  ngOnInit(): void {
    this.shippingAddress = this.isSameBillingAddress.valueChanges.pipe(
      switchMap((change) =>
        change
          ? this.store.select(
              (appStore) => appStore.eshopOrderModule.draft?.billing_address
            )
          : this.formAddressChanges
      ),
      tap((address) => {
        this.store.dispatch(
          OrderActions.configureShippingDetails({
            cost: this.getShippingPrice(address),
            payload: address,
          })
        );
      })
    );
  }
}
