import { Component, Input, inject } from '@angular/core';
import { PreviewComponent } from '../../../../common/preview/preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-s3-image',
  standalone: true,
  imports: [PreviewComponent, CommonModule],
  templateUrl: './s3-image.component.html',
  styleUrl: './s3-image.component.scss',
})
export class S3ImageComponent {
  @Input() url: string;
  @Input() asModalButton: boolean = true;
  @Input() buttonText: string = $localize`Show Image`;

  private modalService = inject(NgbModal);

  openPreview() {
    const previewRef = this.modalService.open(PreviewComponent, {
      size: 'lg',
    });
    previewRef.componentInstance.preview = of(this.url);
  }
}
