<div>
  <fa-icon
    *ngIf="!(initialized | async)"
    [icon]="faCircleNotch"
    [animation]="'spin'"
  ></fa-icon>
  <a
    *ngIf="initialized | async"
    [ngbPopover]="shoppingCartTooltipContent"
    class="d-none d-md-block position-relative"
    i18n-popoverTitle="Shopping Cart Popover Title"
    popoverTitle="Shopping Cart"
  >
    <fa-icon [icon]="faCartShopping" [spin]="false"></fa-icon>
    <span
      *ngIf="(itemCount | async)! > 0"
      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger badge-text"
    >
      {{ (itemCount | async)! }}
    </span>
  </a>
  <a
    *ngIf="initialized | async"
    [routerLink]="['/cart']"
    class="d-block d-md-none position-relative"
    placement="bottom"
  >
    <fa-icon [icon]="faCartShopping" [spin]="false"></fa-icon>
    <span
      *ngIf="(itemCount | async)! > 0"
      class="position-absolute top-0 start-10 translate-middle badge rounded-pill bg-danger badge-text"
    >
      {{ (itemCount | async)! }}
    </span>
  </a>

  <ng-template #shoppingCartTooltipContent>
    <p i18n>Cart items: {{ (itemCount | async)! }}</p>
    <p *ngIf="(cart | async)!.totalPrice >= 0" i18n>
      Total Price: {{ (cart | async)!.totalPrice }}€
    </p>
    <a class="btn btn-secondary" [routerLink]="'/cart'" i18n>Open Cart</a>
  </ng-template>
</div>
