import { createReducer, on } from '@ngrx/store';
import { Concept } from '../../store/concept.types';
import { ConceptAdminAction } from './concept-admin.action';

export interface conceptAdminModuleState {
  concepts: Concept[];
  initialized: boolean;
}

export const initialState: conceptAdminModuleState = {
  concepts: [],
  initialized: false,
};

export const conceptAdminReducer = createReducer(
  initialState,
  on(ConceptAdminAction.refreshConcepts, (_, { concepts }) => {
    return {
      initialized: true,
      concepts,
    };
  })
);
