import { Component, OnInit, inject } from '@angular/core';
import { appStore } from '../../../app.store';
import { Store, createSelector, select } from '@ngrx/store';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { eshopOrdersModuleState } from '../store/order.reducer';
import { CommonModule } from '@angular/common';
import {
  DeliveryProvider,
  Order,
  OrderAddress,
  PaymentProvider,
} from '../store/order.types';
import { AddressService } from '../services/address.service';
import { OrderActions } from '../store/order.action';
import { NgbAccordionModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddAddressComponent } from '../common/add-address/add-address.component';
import { Router } from '@angular/router';
import { FulfillmentService } from '../services/fulfillment.service';
import { AddressCardComponent } from '../common/address-card/address-card.component';
import { DeliveryProviderAccordionItemComponent } from './delivery-provider-accordion-item/delivery-provider-accordion-item.component';
import { PaymentProviderAccordionItemComponent } from './payment-provider-accordion-item/payment-provider-accordion-item.component';
import { OrderService } from '../services/order.service';
import { OrderSummary, orderSummaryReducer } from '../common/utils';
import { OrderSummaryComponent } from '../common/order-summary/order-summary.component';
import {
  CommonAction,
  getRandomAlertId,
} from '../../../common/store/common.action';
import { FormsModule } from '@angular/forms';
import { AnalyticsService } from '../../../analytics.service';

@Component({
  selector: 'app-order-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AddressCardComponent,
    DeliveryProviderAccordionItemComponent,
    PaymentProviderAccordionItemComponent,
    OrderSummaryComponent,
    NgbAccordionModule,
  ],
  templateUrl: './order-create.component.html',
  styleUrl: './order-create.component.scss',
})
export class OrderCreateComponent implements OnInit {
  order: Observable<Partial<Order>>;
  deliveryProviders: Observable<{ [key: string]: DeliveryProvider }>;
  paymentProviders: Observable<{ [key: string]: PaymentProvider }>;
  addresses: Observable<OrderAddress[]>;
  totalGoodsPrice: Observable<number>;
  orderSummary: Observable<OrderSummary>;
  selectedBillingAddress: Observable<OrderAddress | undefined>;
  isDraftReady: Observable<boolean>;
  ordering: boolean = false;
  termsAndConditionsCheckbox: boolean = false;
  placingOrderStr = $localize`Placing order...`;
  orderStr = $localize`Order`;
  order_note = "";

  private modalService = inject(NgbModal);

  draftReady = createSelector(
    (store: appStore) => store.eshopOrderModule.draft,
    (draft) => {
      return (draft?.delivery?.configured &&
        draft?.payment?.configured &&
        !!draft?.billing_address) as boolean;
    }
  );

  orderSummarySelector = createSelector(
    (store: appStore) => store.eshopOrderModule.draft,
    orderSummaryReducer
  );

  constructor(
    private store: Store<appStore>,
    private addressService: AddressService,
    private deliveryService: FulfillmentService,
    private router: Router,
    private orderService: OrderService,
    private analyticsService: AnalyticsService,
  ) { }

  private initializeAdddresses() {
    this.store
      .select((state: appStore) => state.eshopOrderModule)
      .pipe(
        take(1),
        switchMap((state: eshopOrdersModuleState) => {
          if (!state.addressesInitialized) {
            return this.addressService.listAddresses();
          } else {
            return of(null);
          }
        }),
        tap((addresses) => {
          if (addresses !== null) {
            this.store.dispatch(OrderActions.listAddresses({ addresses }));
          }
        })
      )
      .subscribe();

    this.addresses = this.store.select(
      (store) => store.eshopOrderModule.addresses
    );
  }

  private initializeCountries() {
    this.store
      .select((state) => state.eshopOrderModule)
      .pipe(
        take(1),
        switchMap((state) => {
          if (!state.countriesInitialized) {
            return this.addressService.listCountries();
          } else {
            return of(null);
          }
        }),
        tap((countries) => {
          if (countries !== null) {
            this.store.dispatch(OrderActions.listCountries({ countries }));
          }
        })
      )
      .subscribe();
  }

  private initializePaymentProviders() {
    this.store
      .select((state) => state.eshopOrderModule)
      .pipe(
        take(1),
        switchMap((state) => {
          if (!state.paymentProvidersInitialized) {
            return this.deliveryService.listPaymentProviders();
          } else {
            return of(null);
          }
        }),
        tap((paymentProviders) => {
          if (paymentProviders !== null) {
            this.store.dispatch(
              OrderActions.listPaymentProviders({ paymentProviders })
            );
          }
        })
      )
      .subscribe();
  }

  private initializeDeliveryProviders() {
    this.store
      .select((state) => state.eshopOrderModule)
      .pipe(
        take(1),
        switchMap((state) => {
          if (!state.deliveryProvidersInitialized) {
            return this.deliveryService.listDeliveryProviders();
          } else {
            return of(null);
          }
        }),
        tap((deliveryProviders) => {
          if (deliveryProviders !== null) {
            this.store.dispatch(
              OrderActions.listDeliveryProviders({ deliveryProviders })
            );
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    //Redirect to cart if page refreshed and we have no draft in store
    this.order = this.store
      .select((e) => e.eshopOrderModule.draft)
      .pipe(
        tap((draft: any) => {
          if (draft === null) {
            this.router.navigate(['cart']);
          }
        })
      ) as Observable<Partial<Order>>;

    this.initializeAdddresses();
    this.initializeCountries();
    this.initializeDeliveryProviders();
    this.initializePaymentProviders();

    this.totalGoodsPrice = this.store.select((store) =>
      store.eshopOrderModule.draft?.order_items?.reduce((prev, cur) => {
        return prev + cur.priceSnapshot;
      }, 0)
    ) as Observable<number>;

    this.orderSummary = this.store.pipe(select(this.orderSummarySelector));

    this.selectedBillingAddress = this.store.select(
      (store) => store.eshopOrderModule.draft?.billing_address
    );

    this.deliveryProviders = this.store.select(
      createSelector(
        (state: appStore) => state.eshopOrderModule.deliveryProviders,
        (deliveryProviders) => deliveryProviders
      )
    );

    this.paymentProviders = this.store.select(
      createSelector(
        (state: appStore) => state.eshopOrderModule.paymentProviders,
        (paymentProviders) => paymentProviders
      )
    );

    this.isDraftReady = this.store.pipe(select(this.draftReady));
  }

  openAddAddressModal() {
    this.modalService.open(AddAddressComponent, {
      size: 'lg',
    });
  }

  selectBillingAddress(address: OrderAddress) {
    this.store.dispatch(OrderActions.selectBillingAddress({ address }));
  }

  placeOrder() {
    this.ordering = true;
    this.store
      .select((state) => state.eshopOrderModule.draft)
      .pipe(
        take(1),
        switchMap((order) => this.orderService.createOrder(
          {
            ...order!,
            order_note: this.order_note
          }))
      )
      .subscribe({
        next: (order) => {
          this.ordering = false;
          this.store.dispatch(OrderActions.createOrder({ order }));
          this.store.dispatch(
            CommonAction.createAlert({
              alert: {
                id: getRandomAlertId(),
                message: $localize`Order created`,
                type: 'success',
                category: 'order',
                tag: 'order created',
              },
            })
          );
          this.analyticsService.trackConversion('AW-10897858862/CK2mCNOG8OAZEK7CwMwo', {
            value: order.order_items.reduce((prev, cur) => { return prev + cur.priceSnapshot }, 0),
            currency: 'EUR',
            transaction_id: order.id
          });
        },
        error: (err) => {
          this.store.dispatch(
            CommonAction.createAlert({
              alert: {
                id: getRandomAlertId(),
                message:
                  $localize`Failed to create order - ` +
                  (err.error.message ?? $localize`unknown error`),
                type: 'danger',
                category: 'order',
                tag: 'order create failed',
              },
            })
          );
          this.ordering = false;
        },
      });
  }
}
