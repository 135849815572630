import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Concept, ConceptFormat } from './store/concept.types';
import { Socket } from 'ngx-socket-io';
import { Store } from '@ngrx/store';
import { ConceptAction } from './store/concepts.action';
import { environment } from '../../environments/environment';
import { getBackendUrl } from '../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class ConceptsService {
  constructor(
    public httpClient: HttpClient,
    private socket: Socket,
    private store: Store
  ) { }

  debounceTimer: { id: number; time: number }[] = [];

  createConcept(input: File, aspectRatioIdentifier: string) {
    const fd = new FormData();
    fd.append('file', input);
    fd.append('format', aspectRatioIdentifier);
    return this.httpClient.post<Concept>(
      `${getBackendUrl(environment.backendUrl)}/concept`,
      fd,
      {
        withCredentials: true,
      }
    );
  }

  private handleConceptEvents(ev: any) {
    const event: { event: string; data: any } = JSON.parse(ev);

    switch (event.event) {
      case 'progress': {
        const lastTime = this.debounceTimer.find((e) => e.id == event.data.id);

        if (!!lastTime) {
          if (Date.now() - lastTime.time > 1000) {
            // Dispatch with debounce of 1 sec
            this.store.dispatch(
              ConceptAction.updateConceptResultProgress({
                conceptId: event.data.conceptId,
                concept_result_id: event.data.id,
                progress: event.data.progress,
              })
            );
            lastTime.time = Date.now();
          }
        } else {
          // Dispatch on first progress update

          this.store.dispatch(
            ConceptAction.updateConceptResultProgress({
              conceptId: event.data.conceptId,
              concept_result_id: event.data.id,
              progress: event.data.progress,
            })
          );

          const firstDispatch = {
            id: event.data.id,
            time: Date.now(),
          };
          this.debounceTimer.push(firstDispatch);
        }

        break;
      }
      case 'update': {
        // Remove from array on update
        this.debounceTimer = this.debounceTimer.filter(
          (e) => e.id != event.data.id
        );

        this.store.dispatch(
          ConceptAction.updateConceptResult({
            ...event.data,
          })
        );
        break;
      }
      case 'thumbnail': {
        this.store.dispatch(
          ConceptAction.updateThumbnail({
            ...event.data,
          })
        );
      }
    }
  }

  private listenConceptChanges() {
    this.httpClient
      .get<{ token: string; user: string }>(
        `${getBackendUrl(environment.backendUrl)}/concept/wstoken`,
        {
          withCredentials: true,
        }
      )
      .subscribe((data) => {
        this.socket.emit('concept', {
          action: 'subscribe',
          token: data.token,
        });

        this.socket.on(
          `concepts-${data.user}`,
          this.handleConceptEvents.bind(this)
        );
      });
  }

  listConcepts() {
    this.listenConceptChanges();
    return this.httpClient.get<Concept[]>(
      `${getBackendUrl(environment.backendUrl)}/concept`,
      {
        withCredentials: true,
      }
    );
  }

  migrateConcepts() {
    return this.httpClient.post<Concept[]>(
      `${getBackendUrl(environment.backendUrl)}/concept/migrate`,
      {},
      {
        withCredentials: true,
      }
    );
  }

  listFormats() {
    return this.httpClient.get<ConceptFormat[]>(`${getBackendUrl(environment.backendUrl)}/concept/formats`, { withCredentials: true })
  }
}
