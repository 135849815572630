import {
  Component,
  Inject,
} from '@angular/core';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AvatarModule } from 'ngx-avatars';
import { filter } from 'rxjs';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login-button',
  standalone: true,
  imports: [CommonModule, AuthModule, AvatarModule, NgbPopoverModule],
  templateUrl: './login-button.component.html',
  styleUrl: './login-button.component.scss',
})
export class LoginButtonComponent {
  user$ = this.auth.user$.pipe(filter((user) => user !== null));
  authenticated$ = this.auth.isAuthenticated$;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  logout() {
    this.auth.logout({ logoutParams: { returnTo: document.location.origin } })
  }
}
