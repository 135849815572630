import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbProgressbarModule,
} from '@ng-bootstrap/ng-bootstrap';
import { Color, Concept, ConceptResult } from '../store/concept.types';
import { filter, map, Observable, of, switchMap, take } from 'rxjs';
import { Store, StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import {
  faSpinner,
  faStopwatch,
  faHourglassHalf,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';
import { appStore } from '../../app.store';
import { ConceptAction } from '../store/concepts.action';
import { PrivateFileStorageService } from '../../common/private-file-storage.service';
import { PreviewComponent } from '../../common/preview/preview.component';
import { LocalizerPipe } from '../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-concept-result-list',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    StoreModule,
    FontAwesomeModule,
    NgbProgressbarModule,
    LocalizerPipe,
  ],
  templateUrl: './concept-result-list.component.html',
  styleUrl: './concept-result-list.component.scss',
})
export class ConceptResultListComponent {
  @Input() conceptResults$: Observable<ConceptResult[]>;

  // Image spinner waiting for render
  faSpinner = faSpinner;

  // Waiting
  faStopwatch = faStopwatch;

  // Progress icon + progressbar
  faHourglassHalf = faHourglassHalf;

  // Ready - image rendered
  faCheck = faCheck;

  // temp var to store loaded results
  loadedConceptResultList: number[] = [];

  constructor(
    private store: Store<appStore>,
    private privateFileService: PrivateFileStorageService
  ) {}

  getPrivateFile(id: string | null) {
    return this.privateFileService.getFile(id);
  }

  private modalService = inject(NgbModal);
  activeModal = inject(NgbActiveModal);

  openColorPalette(conceptResultId: number) {
    const coloPaletterRef = this.modalService.open(ColorPaletteComponent, {
      size: 'lg',
    });

    this.conceptResults$
      .pipe(
        map((cr) => cr.find((e) => e.id == conceptResultId).color_palette),
        take(1)
      )
      .subscribe((cp) => {
        const clone = [...cp];
        clone.sort((a, b) => {
          return a.colorId > b.colorId ? 1 : -1;
        });
        coloPaletterRef.componentInstance.colorPalette = clone;
      });
  }

  openPreview(conceptResultId: number) {
    const previewRef = this.modalService.open(PreviewComponent, {
      size: 'xl',
    });

    previewRef.componentInstance.preview = this.conceptResults$.pipe(
      map((results) => results.find((e) => e.id == conceptResultId).rendered)
    );
    previewRef.componentInstance.downloadableFilename = 'preview';
  }

  showPrintable(conceptResultId: number) {
    const previewRef = this.modalService.open(PreviewComponent, {
      size: 'xl',
    });

    previewRef.componentInstance.preview = this.conceptResults$.pipe(
      map((results) => results.find((e) => e.id == conceptResultId).svg)
    );
    previewRef.componentInstance.downloadableFilename = 'printable';
  }

  selectConcept(concept_result: ConceptResult) {
    this.store.dispatch(ConceptAction.selectConceptResult({ concept_result }));
    this.activeModal.close();
  }

  conceptResultImgLoaded(conceptResultId: number) {
    this.loadedConceptResultList.push(conceptResultId);
  }
}
