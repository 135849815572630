export const environment = {
  auth0: {
    domain: 'dev-d0rxgd0i8polc8t0.eu.auth0.com',
    clientId: 'EJ84YnCs1uXjFkPb9Elt8H85994EbDQK',
    authorizationParams: {
      audience: 'http://localhost:3000',
      redirect_uri: window.location.origin,
    },
  },
  backendUrl: 'https://paintit-dev-api.spaceup.tbotech.sk',
  backendUrlReplacements: [
    {
      lang: 'sk',
      from: '',
      to: '',
    },
  ],
  packeta: {
    apikey: '958b4689aa32499f',
  },
};

//  backendUrl: 'https://bull-literate-stallion.ngrok-free.app',
//  backendUrl: 'https://paintit-dev-api.spaceup.tbotech.sk',
