import { Injectable } from '@angular/core';

declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  trackEvent(event_label: string, value: string, event_category: string) {
    gtag('event', event_label, {
      event_category,
      event_label,
      value
    })
  }

  trackConversion(send_to: string, params: any) {
    gtag('event', 'conversion', {
      ...params,
      send_to
    })
  }
  constructor() { }
}
