import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-packeta-cash-on-delivery',
  standalone: true,
  imports: [],
  template: ` <p i18n>Cash on delivery. You will pay at the pick-up location</p> `,
  styleUrl: './packeta-cash-on-delivery.component.scss',
})
export class PacketaCashOnDeliveryComponent {
  @Input() payload: any;
}
