import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import {
  Observable,
  Subscription,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Concept } from '../store/concept.types';
import { appStore } from '../../app.store';
import { ConceptsService } from '../concepts.service';
import { ConceptAction } from '../store/concepts.action';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConceptResultListComponent } from '../concept-result-list/concept-result-list.component';
import { conceptModuleState } from '../store/concepts.reducer';
import { PrivateFileStorageService } from '../../common/private-file-storage.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConceptComponent } from '../concept/concept.component';
import { ProductPlaceholderComponent } from '../../eshop/products/product-placeholder/product-placeholder.component';
import { ConceptPlaceholderComponent } from '../concept-placeholder/concept-placeholder.component';

@Component({
  selector: 'app-concept-list',
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule,
    FontAwesomeModule,
    NgxPaginationModule,
    ConceptComponent,
    ConceptPlaceholderComponent,
  ],
  templateUrl: './concept-list.component.html',
  styleUrl: './concept-list.component.scss',
})
export class ConceptListComponent implements OnInit {
  concepts: Observable<Concept[] | any> = of<Concept[]>([]);
  faSpinner = faSpinner;
  currentPage: number = 1;
  initialized: Observable<boolean>;
  paginationControlNextStr: string = $localize`Next`;
  paginationControlPreviousStr: string = $localize`Previous`;

  isFiltered: Observable<{ filtered: boolean; productName?: string }>;

  constructor(
    private store: Store<appStore>,
    private conceptService: ConceptsService,
    private privateFileService: PrivateFileStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  getPrivateFile(id: string | null) {
    return this.privateFileService.getFile(id);
  }

  ngOnInit(): void {
    this.isFiltered = this.activatedRoute.queryParams.pipe(
      map((e) =>
        'product' in e
          ? { filtered: true, productName: e['name'] }
          : { filtered: false }
      )
    );

    this.concepts = this.activatedRoute.queryParams.pipe(
      switchMap((e) =>
        this.store.select((state) =>
          state.conceptModule.concepts.filter((c) =>
            'product' in e
              ? c.concept_format.product_slugs.includes(e['product'])
              : c
          )
        )
      )
    );

    this.initialized = this.store.select(
      (state) => state.conceptModule.initialized
    );

    this.store
      .select((state: appStore) => state.conceptModule)
      .pipe(
        filter((module) => !module.initialized),
        take(1),
        switchMap((module) => this.conceptService.listConcepts())
      )
      .subscribe((concepts) => {
        this.store.dispatch(ConceptAction.refreshConcepts({ concepts }));
      });
  }

  pageChange($event: any) {
    this.currentPage = $event;
  }

  resetFilter() {
    this.router.navigate(['/concepts']);
  }
}
