<div class="modal-header">
  <h4 class="modal-title" i18n>Preview</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <img [src]="preview | async" class="img-fluid"/>
</div>
<div class="modal-footer">
  <button (click)="downloadImage()" class="btn btn-outline-secondary">
    Download
  </button>

  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="activeModal.close('Close click')"
    i18n
  >
    Close
  </button>
</div>
