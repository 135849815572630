<div *ngIf="(order | async)!.billing!">
  <div *ngIf="!(order | async)!.billing!.loading">
    <div *ngIf="!(order | async)!.billing!.error">
      <div class="py-2" i18n>
        You have decided to pay your invoice via a bank transfer. Please reveiew
        your payment options below:
      </div>

      <p class="fw-lighter">
        <span>IBAN: </span
        ><span>{{
          (order | async)!.billing!.details!.paymentDetails.iban
        }}</span>
        <br />
        <span i18n>Payment Reference (Variable Symbol): </span
        ><span>{{
          (order | async)!.billing!.details!.paymentDetails.payment_reference
        }}</span>
        <br />
        <span i18n>Amount to pay: </span
        ><span
          >{{ (order | async)!.billing!.details!.paymentDetails.amount }}€</span
        >
        <br />
        <span i18n="invoice state">State: </span>
        <ng-container
          [ngSwitch]="(order | async)!.billing!.details!.paymentState"
        >
          <span *ngSwitchCase="'paid'" class="text-success" i18n>Paid</span>
          <span *ngSwitchCase="'not_paid'" class="text-danger" i18n
            >Not Paid</span
          >
          <span *ngSwitchCase="'partial'" class="test-primary" i18n
            >Partially Paid</span
          >
          <span *ngSwitchCase="'reversed'" i18n>Payment retuned</span>
        </ng-container>
      </p>
    </div>
    <div *ngIf="(order | async)!.billing!.error" class="text-danger" i18n>
      Failed to load payment information
    </div>
  </div>
</div>
