import { createActionGroup, props } from '@ngrx/store';
import { Alert } from './common.types';

export const getRandomAlertId = () => {
  return Math.floor(Math.random() * 99999)
}

export const CommonAction = createActionGroup({
  source: 'common',
  events: {
    createAlert: props<{ alert: Alert }>(),
    closeAlert: props<{ id: number }>(),
  },
});
