import { CustomLanguageConfig } from "@localia/ngx-cookie-consent/lib/config/custom-language-config.interface";

export const lang_sk: CustomLanguageConfig = {
  languageKey: 'sk',
  languageName: 'Slovenský',
  customIconPath: null,
  translations: {
    language: 'Slovenský',
    title: 'Vážime si Vaše súkromie',
    text: 'My a naši partneri používame technológie, ako sú súbory cookie alebo zacielenie, a spracúvame osobné údaje, ako je adresa IP alebo informácie o prehliadači, na prispôsobenie reklám, ktoré zobrazujeme. Tieto technológie môžu pristupovať k vášmu zariadeniu a pomôcť nám zobrazovať vám relevantnejšie reklamy a zlepšiť zážitok z vášho webu. Tieto technológie využívame aj na meranie výsledkov alebo lepšie zacielenie obsahu našich webových stránok. Pretože si vážime vaše súkromie, týmto vás žiadame o súhlas s používaním nasledujúcich technológií.',
    left_bottom:
      'Vaše nastavenia môžete kedykoľvek zmeniť kliknutím na ikonu na ľavej, spodnej strany obrazovky',
    privacy_text: 'Zásady ochrany osobných údajov',
    imprint_text: 'Kontakt',
    privacy_settings_text: 'Nastavenia súkromia',
    privacy_settings_title: 'Nastavenia súkromia',
    privacy_settings_title_text:
      'Tento nástroj vám pomáha vybrať a deaktivovať rôzne súbory cookie, sledovače a analytické nástroje používané na tejto webovej lokalite.',
    functional_title: 'Funčné',
    functional_description:
      'Tieto súbory cookie nám umožňujú analyzovať používanie webovej stránky, aby sme mohli merať a zlepšovať jej výkonnosť.',
    marketing_title: 'Marketingové',
    marketing_description:
      'Marketingové súbory cookie sa zvyčajne používajú na zobrazovanie reklám, ktoré zodpovedajú vašim záujmom. Keď navštívite inú webovú stránku, je rozpoznaný súbor cookie vášho prehliadača a na základe informácií uložených v tomto súbore cookie sa vám zobrazia vybrané reklamy (článok 6 ods. 1 s. 1 a) GDPR).',
    essential_title: 'Nevyhnutné',
    essential_description:
      'Tieto cookies sú potrebné pre základné funkcie webovej stránky',
    other_title: 'Iné',
    other_description:
      'V rámci našej organizácie využívame ďalšie nástroje na spracovanie údajov. Tie sú tu uvedené aj na informačné účely. Dáta sa však zbierajú až po určitej interakcii, napríklad keď sa dohodnete na ukážke. Ak chcete namietať proti spracovaniu údajov týmito spracovateľmi, kontaktujte nás.',
    save_text: 'Uložiť',
    accept_text: 'Akceptovať a zavrieť',
    decline_text: 'Odmietnúť',
    choose_language_text: 'Zvoľte si jazyk',
    back_text: 'Späť',
    cookie_name: 'Názov',
    cookie_description: 'Popis',
    cookie_duration: 'Dĺžka živostnosti',
  }
}