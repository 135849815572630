import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { appStore } from '../../app.store';
import { Store, StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { eshopCartModuleState } from './store/cart.reducer';
import { Observable, Subscription, take, tap } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CartPaintByNumbersComponent } from './cart-paint-by-numbers/cart-paint-by-numbers.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { CartService } from './cart.service';
import { CartAction } from './store/cart.actions';
import { Router, RouterModule } from '@angular/router';
import { OrderActions } from '../order/store/order.action';

@Component({
  selector: 'app-cart',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    StoreModule,
    FontAwesomeModule,
    CartPaintByNumbersComponent,
    ItemDetailsComponent,
    RouterModule,
  ],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss',
})
export class CartComponent implements OnInit, OnDestroy {
  cart: Observable<eshopCartModuleState>;
  private cartSnapshot: eshopCartModuleState;
  private cartSnapshotSubscription: Subscription;

  faSpinner = faSpinner;
  faTrashCan = faTrashCan;

  constructor(
    private store: Store<appStore>,
    private cartService: CartService
  ) {}

  ngOnDestroy(): void {
    this.cartSnapshotSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.cart = this.store.select((state) => state.eshopCartModule);

    this.cartSnapshotSubscription = this.cart.subscribe((cart) => {
      this.cartSnapshot = cart;
    });
  }

  deleteCartItem(id: number) {
    this.cartService
      .deleteCartItem(id)
      .pipe(
        tap((cart) => {
          this.store.dispatch(CartAction.refreshCart({ cart }));
        })
      )
      .subscribe();
  }

  createOrderDraft() {
    this.store.dispatch(
      OrderActions.createDraft({ cart: this.cartSnapshot.cart })
    );
  }
}
