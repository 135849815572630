<h2 i18n>Orders</h2>

<section class="h-100">
  <div class="container h-100 py-5">
    <div
      class="row d-flex justify-content-between align-items-center h-80 text-center"
    >
      <!-- header -->
      <div class="card rounded-3 mb-4">
        <div class="card-body p-4">
            <div class="row d-flex align-items-center">
                <div class="col-3" i18n>Order Date</div>
                <div class="col-3" i18n>Order ID</div>
                <div class="col-3" i18n>Status</div>
                <div class="col-3" i18n>Total Price</div>
            </div>
        </div>
      </div>

      <!-- Item -->
      <div class="card rounded-3 mb-4" *ngFor="let order of orders | async">
        <a
          [routerLink]="['/order', 'detail', order.order.id]"
          class="text-reset link-underline link-underline-opacity-0"
        >
          <div class="card-body p-4">
            <div class="row d-flex align-items-center">
              <!-- Order Date -->
              <div class="col-3">
                <p class="fw-normal mb-0">
                  {{ order.order.created_at | date : "mediumDate" }}
                </p>
              </div>

              <!-- ID -->
              <div class="col-3">
                <p class="fw-normal mb-0">{{ order.order.id }}</p>
              </div>

              <!-- Status -->
              <div class="col-3">
                <p class="fw-normal mb-0">{{ order.order.state | xlocalize:stateLocales[order.order.state] | titlecase }}</p>
              </div>

              <!-- Total Price -->
              <div class="col-3">
                <h5 class="mb-0">{{ order.totalPrice }}€</h5>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="page-layout" *ngIf="!(ordersInitialized | async)">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center w-100 p-5">
        <p class="h3">
          <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
          <span i18n>Loading Orders...</span>
        </p>
      </div>
    </div>
  </div>
</section>
