import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AnonSessionStore } from './auth.reducer';
import { Cart } from '../../eshop/cart/store/cart.types';
import { Concept } from '../../concepts/store/concept.types';
import { Order, OrderAddress } from '../../eshop/order/store/order.types';

export const AuthAction = createActionGroup({
  source: 'auth',
  events: {
    permissionsFetched: props<{ permissions: string[] }>(),
    anonStateFetched: props<{ anonState: AnonSessionStore }>(),
    anonMigrationRequest: props<{ anonState: AnonSessionStore }>(),
    anonCartMigrated: props<{ cart: Cart }>(),
    anonConceptMigrated: props<{ concepts: Concept[] }>(),
    anonAddressMigrated: props<{ addresses: OrderAddress[] }>(),
    anonOrderMigrated: props<{ orders: Order[] }>(),
    anonMigrated: props<{ state: boolean }>(),
  },
});
