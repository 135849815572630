import {
  AfterContentInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Country,
  DeliveryCountryPrice,
  DeliveryDetails,
  OrderAddress,
  PaymentProvider,
} from '../../../store/order.types';
import { Observable, Subscription } from 'rxjs';
import { PacketaPickupDetails } from '../../delivery-method/packeta-pickup/packeta-pickup.component';
import { Store, createSelector } from '@ngrx/store';
import { appStore } from '../../../../../app.store';
import { CommonModule } from '@angular/common';
import { OrderActions } from '../../../store/order.action';
import { LocalizerPipe } from '../../../../../common/pipes/countryLocalePipe';

interface Delivery {
  delivery: DeliveryDetails | undefined;
  country: DeliveryCountryPrice | undefined;
  cost: number;
}

@Component({
  selector: 'app-packeta-cash-on-delivery',
  standalone: true,
  imports: [CommonModule, LocalizerPipe],
  templateUrl: './packeta-cash-on-delivery.component.html',
  styleUrl: './packeta-cash-on-delivery.component.scss',
})
export class PacketaCashOnDeliveryComponent implements OnInit, OnDestroy {
  @Input()
  paymentProvider: PaymentProvider;

  delivery: Observable<Delivery>;
  paymentUpdates: Subscription;

  private getCountryFromProvider(
    st: DeliveryDetails | undefined
  ): DeliveryCountryPrice | undefined {
    if (st !== undefined) {
      if (st.provider.component_type == 'packeta_pickup_selector') {
        return st.provider.delivery_country_price.find(
          (e) =>
            e.country.name == (st.payload as PacketaPickupDetails)?.country ||
            e.country.additionalIdentifiers.includes(
              (st.payload as PacketaPickupDetails)?.country
            )
        );
      }

      if (st.provider.component_type == 'packeta_address_selector') {
        return st.provider.delivery_country_price.find(
          (e) => e.country.id == (st.payload as OrderAddress)?.country.id
        );
      }
    }

    return undefined;
  }

  shippingPayloadSelector = createSelector(
    (state: appStore) => state.eshopOrderModule.draft?.delivery,
    (st) => {
      return {
        delivery: st,
        country: this.getCountryFromProvider(st),
        cost:
          this.paymentProvider.payment_country_price.find(
            (e) => e.country.id == this.getCountryFromProvider(st)?.country.id
          )?.cost ?? 0,
      };
    }
  );

  ngOnInit(): void {
    this.delivery = this.store.select(this.shippingPayloadSelector);

    //This needs to be done this way due to NG0100: ExpressionChangedAfterItHasBeenCheckedError in parent compoenent
    setTimeout(
      () =>
        (this.paymentUpdates = this.store
          .select(this.shippingPayloadSelector)
          .subscribe((e) => {
            this.store.dispatch(
              OrderActions.configurePaymentDetails({
                cost: e.cost,
                payload: e.country?.country,
              })
            );
          })),
      0
    );
  }

  ngOnDestroy(): void {
    this.paymentUpdates?.unsubscribe();
  }

  constructor(private store: Store<appStore>) {}
}
