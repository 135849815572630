import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { appStore } from '../../../app.store';
import { Locale, Order } from '../store/order.types';
import { Observable, map, of, switchMap, take, tap } from 'rxjs';
import { Store, createSelector, select } from '@ngrx/store';
import { OrderService } from '../services/order.service';
import { OrderActions } from '../store/order.action';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LocalizerPipe } from '../../../common/pipes/countryLocalePipe';

interface OderWithPriceSnapshot {
  order: Order;
  totalPrice: number;
}

@Component({
  selector: 'app-order-list',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [FontAwesomeModule, CommonModule, RouterModule, LocalizerPipe],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss',
})
export class OrderListComponent implements OnInit {
  orders: Observable<OderWithPriceSnapshot[]>;
  ordersInitialized: Observable<boolean>;
  faSpinner = faSpinner;

  stateLocales: Record<string, Locale[]> = {
    CREATED: [{ locale: 'sk', value: 'Vytvorená' }],
    PREPARING: [{ locale: 'sk', value: 'Pripravuje sa' }],
    PREPARED: [{ locale: 'sk', value: 'Pripravená' }],
    SHIPPED: [{ locale: 'sk', value: 'Odoslaná' }],
    COMPLETED: [{ locale: 'sk', value: 'Vybavená' }],
    RETURNED: [{ locale: 'sk', value: 'Vrátená' }],
    CANCELLED: [{ locale: 'sk', value: 'Zrušená' }],
  };

  orderWithPriceSelector = createSelector(
    (state: appStore) => state.eshopOrderModule.orders,
    (orders) =>
      orders.map((order) => {
        return {
          order: order,
          totalPrice:
            order.order_items.reduce(
              (prev, cur) => prev + cur.priceSnapshot,
              0
            ) +
            order.payment.cost +
            order.delivery.cost,
        };
      })
  );

  constructor(
    private store: Store<appStore>,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.orders = this.store.pipe(select(this.orderWithPriceSelector));
    this.ordersInitialized = this.store.select(
      (store) => store.eshopOrderModule.ordersInitialized
    );

    this.store
      .select((store) => store.eshopOrderModule)
      .pipe(
        take(1),
        switchMap((state) => {
          if (!state.ordersInitialized) {
            return this.orderService.listOrders();
          } else {
            return of(null);
          }
        }),
        tap((orders) => {
          if (orders !== null) {
            this.store.dispatch(OrderActions.refreshOrders({ orders }));
          }
        })
      )
      .subscribe();
  }
}
