import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BillingInformation } from '../store/order.types';
import { getBackendUrl } from '../../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private httpClient: HttpClient) {}

  getBillingDetails(orderId: number) {
    return this.httpClient.get<BillingInformation>(
      `${getBackendUrl(environment.backendUrl)}/billing/invoice/${orderId}`,
      { withCredentials: true, params: { locale: $localize.locale ?? 'en' } }
    );
  }
}
