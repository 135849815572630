<div class="card rounded-3 mb-4">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-12 fw-bold">
        <h4 i18n>Shipping information:</h4>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center">
      <ng-container [ngSwitch]="deliveryDetails.provider.component_type">
        <div *ngSwitchCase="'packeta_pickup_selector'">
          <app-packeta-pickup
            [payload]="deliveryDetails.payload"
          ></app-packeta-pickup>
        </div>
        <div *ngSwitchCase="'packeta_address_selector'">
          <app-packeta-address
            [payload]="deliveryDetails.payload"
          ></app-packeta-address>
        </div>
      </ng-container>
    </div>
  </div>
</div>
