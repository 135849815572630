import { Component } from '@angular/core';

@Component({
  selector: 'app-product-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './product-placeholder.component.html',
  styleUrl: './product-placeholder.component.scss'
})
export class ProductPlaceholderComponent {

}
