import { authReducer, authState } from './auth/store/auth.reducer';
import {
  commonModuleState,
  commonReducer,
} from './common/store/common.reducer';
import {
  conceptAdminModuleState,
  conceptAdminReducer,
} from './concepts/admin/store/concept-admin.reducer';
import {
  conceptModuleState,
  conceptReducer,
} from './concepts/store/concepts.reducer';
import {
  eshopCartModuleState,
  eshopCartReducer,
} from './eshop/cart/store/cart.reducer';
import {
  eshopOrdersModuleState,
  eshopOrderReducer,
} from './eshop/order/store/order.reducer';
import {
  eshopProductModuleState,
  eshopProductReducer,
} from './eshop/products/store/product.reducer';

export const appStoreReducers = {
  conceptModule: conceptReducer,
  eshopProductModule: eshopProductReducer,
  eshopCartModule: eshopCartReducer,
  eshopOrderModule: eshopOrderReducer,
  commonModule: commonReducer,
  auth: authReducer,
  adminConcept: conceptAdminReducer,
};

export interface appStore {
  conceptModule: conceptModuleState;
  eshopProductModule: eshopProductModuleState;
  eshopCartModule: eshopCartModuleState;
  eshopOrderModule: eshopOrdersModuleState;
  commonModule: commonModuleState;
  auth: authState;
  adminConcept: conceptAdminModuleState;
}
