import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Store, StoreModule } from '@ngrx/store';
import { appStore } from '../../../../app.store';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, take, tap } from 'rxjs';
import { ProductAction } from '../../store/products.action';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Product } from '../../store/product.types';

@Component({
  selector: 'app-count-selector',
  standalone: true,
  imports: [CommonModule, StoreModule, FontAwesomeModule],
  templateUrl: './count-selector.component.html',
  styleUrl: './count-selector.component.scss',
})
export class CountSelectorComponent implements OnInit, OnDestroy {
  productSnapshot: BehaviorSubject<any>;
  productSnapshotSubscription: Subscription;
  product: Observable<Product>;

  faPlus = faPlus;
  faMinus = faMinus;

  @Input() productId: number;

  count: FormControl = new FormControl<number>(1);

  constructor(private store: Store<appStore>) {}

  ngOnInit(): void {
    this.product = this.store.select(
      (appState) =>
        appState.eshopProductModule.products.find(
          (e) => e.id == this.productId
        ) as Product
    );

    this.productSnapshot = new BehaviorSubject(null);
    this.productSnapshotSubscription = this.product.subscribe((product) => {
      this.productSnapshot.next(product);
    });
  }

  ngOnDestroy(): void {
    this.productSnapshotSubscription.unsubscribe();
  }

  incrementCount() {
    this.productSnapshot
      .pipe(
        take(1),
        tap((prod) => {
          this.store.dispatch(
            ProductAction.incrementProductCount({ id: prod.id })
          );
        })
      )
      .subscribe();
  }

  decrementCount() {
    this.productSnapshot
      .pipe(
        take(1),
        tap((prod) => {
          this.store.dispatch(
            ProductAction.decrementProductCount({ id: prod.id })
          );
        })
      )
      .subscribe();
  }
}
