<div class="modal-header">
  <h4 class="modal-title" i18n>Add new address - {{ formType | titlecase }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <form [formGroup]="newAddressForm" (ngSubmit)="addAddress()" class="row g-3">
    <!-- name -->
    <div class="form-group col-12">
      <label for="name" class="form-label" i18n>Name</label>
      <input
        type="text"
        id="name"
        [formControlName]="'name'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('name')" class="invalid-feedback" i18n>
        Name is required.
      </div>
    </div>

    <!-- address -->
    <div class="form-group col-12">
      <label for="address" class="form-label" i18n>Address</label>
      <input
        type="text"
        id="address"
        [formControlName]="'address'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('address')" class="invalid-feedback" i18n>
        Address is required.
      </div>
    </div>

    <!-- city / country / ZIP_PC -->
    <div class="form-group col-md-6">
      <label for="city" class="form-label" i18n>City</label>
      <input
        type="text"
        id="city"
        [formControlName]="'city'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('city')" class="invalid-feedback" i18n>
        City is required.
      </div>
    </div>

    <div class="form-group col-md-4">
      <label for="country" class="form-label" i18n>Country</label>
      <select class="form-select" id="country" [formControlName]="'country'">
        <option *ngFor="let country of countries | async" [value]="country.id">
          {{ country.name | xlocalize:country.locale }}
        </option>
      </select>
      <div *ngIf="isRequiredFulfilled('country')" class="invalid-feedback" i18n>
        Country is required.
      </div>
    </div>

    <div class="form-group col-md-2">
      <label for="postal_code" class="form-label" i18n>Postal Code</label>
      <input
        type="text"
        id="postal_code"
        [formControlName]="'postal_code'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('postal_code')" class="invalid-feedback" i18n>
        Postal code is required.
      </div>
    </div>

    <!-- Phone / Email -->
    <div class="form-group col-md-6">
      <label for="phone_number" class="form-label" i18n>Phone Number</label>
      <input
        type="text"
        id="phone_number"
        [formControlName]="'phone_number'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('phone_number')" class="invalid-feedback" i18n>
        Phone number is required.
      </div>
      <div *ngIf="isFulFilled('phone_number', 'pattern')" class="invalid-feedback" i18n>
        Phone number must be in international format with no spaces. e.g. +421911111111
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="email" class="form-label" i18n>Email</label>
      <input
        type="text"
        id="email"
        [formControlName]="'email'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('email')" class="invalid-feedback" i18n>
        Email is required.
      </div>
    </div>

    <!-- is business -->
    <div class="col-12" *ngIf="['billing'].includes(formType)">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="isBusiness"
          [formControlName]="'isBusiness'"
        />
        <label class="form-check-label" for="isBusiness" i18n>Is Business?</label>
      </div>
    </div>

    <!-- company_name / company_id / vat_id -->
    <div class="form-group col-md-6" *ngIf="isBusiness | async">
      <label for="company_name" class="form-label" i18n>Company Name</label>
      <input
        type="text"
        id="company_name"
        [formControlName]="'company_name'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('company_name')" class="invalid-feedback" i18n>
        Company name is required.
      </div>
    </div>
    <div class="form-group col-md-3" *ngIf="isBusiness | async">
      <label for="company_id" class="form-label" i18n>Company ID</label>
      <input
        type="text"
        id="company_id"
        [formControlName]="'company_id'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('company_id')" class="invalid-feedback" i18n>
        Company ID is required.
      </div>
    </div>
    <div class="form-group col-md-3" *ngIf="isBusiness | async">
      <label for="vat_id" class="form-label" i18n>VAT ID</label>
      <input
        type="text"
        id="vat_id"
        [formControlName]="'vat_id'"
        class="form-control"
      />
      <div *ngIf="isRequiredFulfilled('vat_id')" class="invalid-feedback" i18n>
        VAT ID is required.
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="addAddress()"
    [disabled]="newAddressForm.invalid"
    i18n
  >
    Add
  </button>
</div>
