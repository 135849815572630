import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Country, OrderAddress } from '../store/order.types';
import { environment } from '../../../../environments/environment';
import { getBackendUrl } from '../../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private httpClient: HttpClient) {}

  listAddresses() {
    return this.httpClient.get<OrderAddress[]>(
      `${getBackendUrl(environment.backendUrl)}/addresses`,
      { withCredentials: true }
    );
  }

  addAddress(address: Partial<OrderAddress>) {
    return this.httpClient.post<OrderAddress>(
      `${getBackendUrl(environment.backendUrl)}/addresses`,
      address,
      {
        withCredentials: true,
      }
    );
  }

  listCountries() {
    return this.httpClient.get<Country[]>(
      `${getBackendUrl(environment.backendUrl)}/addresses/countries`
    );
  }

  migrateAddresses() {
    return this.httpClient.post<OrderAddress[]>(
      `${getBackendUrl(environment.backendUrl)}/addresses/migrate`,
      {},
      { withCredentials: true }
    );
  }
}
