import { Component, Input } from '@angular/core';
import { AddressCardComponent } from '../../common/address-card/address-card.component';
import { OrderSummary } from '../../common/utils';
import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-billing-info',
  standalone: true,
  imports: [AddressCardComponent, CommonModule, FontAwesomeModule],
  templateUrl: './billing-info.component.html',
  styleUrl: './billing-info.component.scss',
})
export class BillingInfoComponent {
  @Input() order: Observable<OrderSummary | null>;
  constructor() {}
}
