import { Component, Input } from '@angular/core';
import { Order } from '../../store/order.types';
import { CommonModule } from '@angular/common';
import { UserInputPipe } from '../../../../common/pipes/isUserInputOrderItemOptionPipe';
import { S3ImageComponent } from '../s3-image/s3-image.component';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';

@Component({
  selector: 'app-order-items-summary',
  standalone: true,
  imports: [
    CommonModule,
    UserInputPipe,
    S3ImageComponent,
    ColorPaletteComponent,
  ],
  templateUrl: './order-items-summary.component.html',
  styleUrl: './order-items-summary.component.scss',
})
export class OrderItemsSummaryComponent {
  @Input() order: Partial<Order>;

  sourceText: string = $localize`Show Design`;
  svgText: string = $localize`Show Printable`;

  constructor() {}
}
