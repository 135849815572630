import { Component, Input, OnInit } from '@angular/core';
import { CartItem } from '../store/cart.types';
import { PrivateFileStorageService } from '../../../common/private-file-storage.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cart-paint-by-numbers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cart-paint-by-numbers.component.html',
  styleUrl: './cart-paint-by-numbers.component.scss',
})
export class CartPaintByNumbersComponent implements OnInit {
  @Input() cartItem: CartItem;

  imgSrc: string;

  constructor(private fileStorageService: PrivateFileStorageService) {}

  ngOnInit(): void {
    this.imgSrc = this.cartItem.cart_item_options.find(
      (e) => e.product_option.key == 'rendered'
    )?.valueOverride as string;
  }
}
