<button
  *ngIf="asModalButton"
  type="button"
  class="btn btn-outline-secondary btn-sm"
  (click)="openPreview()"
>
  {{ buttonText }}
</button>
<div *ngIf="!asModalButton">
  <img class="img-fluid" [src]="url" />
</div>
