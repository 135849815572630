import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, tap } from 'rxjs';
import { AddressService } from '../../services/address.service';
import { Country, OrderAddress } from '../../store/order.types';
import { Store, StoreModule } from '@ngrx/store';
import { OrderActions } from '../../store/order.action';
import { appStore } from '../../../../app.store';
import { LocalizerPipe } from '../../../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-add-address',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, StoreModule, LocalizerPipe],
  templateUrl: './add-address.component.html',
  styleUrl: './add-address.component.scss',
})
export class AddAddressComponent implements OnInit, OnDestroy {
  @Input()
  formType: string = 'billing';

  @Output()
  formFilled: EventEmitter<OrderAddress> = new EventEmitter();

  activeModal = inject(NgbActiveModal);

  countries: Observable<Country[]>;

  isBusinessSubscription: Subscription;
  isBusiness: Observable<boolean>;

  newAddressForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    postal_code: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    phone_number: new FormControl('', [
      Validators.required,
      Validators.pattern(/\+\d{1,}/),
    ]),
    email: new FormControl('', [Validators.required]),
    isBusiness: new FormControl(false, [Validators.required]),
    company_name: new FormControl(''),
    company_id: new FormControl(''),
    vat_id: new FormControl(''),
  });

  constructor(
    private addressService: AddressService,
    private store: Store<appStore>
  ) {}

  ngOnDestroy(): void {
    this.isBusinessSubscription.unsubscribe();
    this.formFilled.complete();
  }

  ngOnInit(): void {
    this.isBusinessSubscription = this.newAddressForm
      .get('isBusiness')!
      ?.valueChanges.subscribe((isBusiness) => {
        const controls = ['company_name', 'company_id', 'vat_id'];

        if (isBusiness) {
          controls.forEach((control) => {
            this.newAddressForm
              .get(control)!
              .setValidators([Validators.required]);
          });
        } else {
          controls.forEach((control) => {
            this.newAddressForm.get(control)!.clearValidators();
          });
        }

        controls.forEach((control) => {
          this.newAddressForm.get(control)!.updateValueAndValidity();
        });
      });

    this.isBusiness = this.newAddressForm.get('isBusiness')
      ?.valueChanges as Observable<boolean>;

    this.countries = this.store.select(
      (state) => state.eshopOrderModule.countries
    );
  }

  addAddress() {
    this.addressService
      .addAddress({
        ...(this.newAddressForm.value as unknown as Partial<OrderAddress>),
        country: +this.newAddressForm.value.country! as any,
      })
      .pipe(
        tap((address) => {
          this.formFilled.emit(address);
          switch (this.formType) {
            case 'billing': {
              this.store.dispatch(OrderActions.addAddress({ address }));
              break;
            }
            case 'shipping': {
              //no action is dispatched here;
              break;
            }
          }
        })
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  get name() {
    return this.newAddressForm.get('name') as FormControl;
  }

  isRequiredFulfilled(name: string) {
    const control = this.newAddressForm.get(name) as FormControl;
    return (
      control.invalid &&
      (control.dirty || control.touched) &&
      control.errors?.['required']
    );
  }

  isFulFilled(name: string, validatorName: string) {
    const control = this.newAddressForm.get(name) as FormControl;
    return (
      control.invalid &&
      (control.dirty || control.touched) &&
      control.errors?.[validatorName]
    );
  }
}
