import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { filter } from 'rxjs';

@Component({
  selector: 'app-frontpage',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './frontpage.component.html',
  styleUrl: './frontpage.component.scss',
})
export class FrontpageComponent implements OnInit {
  constructor(private router: Router, public auth: AuthService) {}

  url: string = '/';

  imgSourceUrl = $localize`https://numeroart.fra1.digitaloceanspaces.com/numeroart/assets/site/portal_frontpage_en.png`;

  ngOnInit() {
    this.url = this.router.url;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        console.log(e);
        this.url = (e as NavigationEnd).url;
      });
  }
}
