import { createActionGroup, props } from '@ngrx/store';
import { Cart } from './cart.types';

export const CartAction = createActionGroup({
  source: 'cart',
  events: {
    refreshCart: props<{ cart: Cart }>(),
  },
});

