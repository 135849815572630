import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, map, of, switchMap, tap } from 'rxjs';
import { ConceptsService } from '../../concepts/concepts.service';
import { appStore } from '../../app.store';
import { AuthAction } from './auth.action';
import { CartService } from '../../eshop/cart/cart.service';
import { OrderService } from '../../eshop/order/services/order.service';
import { AddressService } from '../../eshop/order/services/address.service';
import { ConceptAction } from '../../concepts/store/concepts.action';
import { CartAction } from '../../eshop/cart/store/cart.actions';
import { OrderActions } from '../../eshop/order/store/order.action';
import { ProfileService } from '../profile.service';

@Injectable()
export class AnonMigrationEffects {
  constructor(
    private action$: Actions,
    private conceptService: ConceptsService,
    private cartService: CartService,
    private orderService: OrderService,
    private addressService: AddressService
  ) {}

  anonCartMigration$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonMigrationRequest),
      switchMap((anonState) =>
        anonState.anonState.cartUsed
          ? this.cartService.migrateCart()
          : this.cartService.getCart()
      ),
      switchMap((cart) => of(AuthAction.anonCartMigrated({ cart })))
    )
  );

  anonConceptMigration$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonMigrationRequest),
      switchMap((anonState) =>
        anonState.anonState.conceptCreated
          ? this.conceptService.migrateConcepts()
          : this.conceptService.listConcepts()
      ),
      switchMap((concepts) => of(AuthAction.anonConceptMigrated({ concepts })))
    )
  );

  anonAddressMigration$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonMigrationRequest),
      switchMap((anonState) =>
        anonState.anonState.addressAdded
          ? this.addressService.migrateAddresses()
          : this.addressService.listAddresses()
      ),
      switchMap((addresses) =>
        of(AuthAction.anonAddressMigrated({ addresses }))
      )
    )
  );

  anonOrderMigration$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonMigrationRequest),
      switchMap((anonState) =>
        anonState.anonState.orderCreated
          ? this.orderService.migrateOrders()
          : this.orderService.listOrders()
      ),
      switchMap((orders) => of(AuthAction.anonOrderMigrated({ orders })))
    )
  );
}

@Injectable()
export class AnonMigratedEffects {
  constructor(
    private action$: Actions,
    private profileService: ProfileService
  ) {}

  anonCartMigrated$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonCartMigrated),
      switchMap((a) => of(CartAction.refreshCart({ cart: a.cart })))
    )
  );

  anonAddressMigrated$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonAddressMigrated),
      switchMap((a) =>
        of(OrderActions.listAddresses({ addresses: a.addresses }))
      )
    )
  );

  anonOrdersMigrated$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonOrderMigrated),
      switchMap((a) => of(OrderActions.refreshOrders({ orders: a.orders })))
    )
  );

  anonConceptsMigrated$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthAction.anonConceptMigrated),
      switchMap((a) =>
        of(ConceptAction.refreshConcepts({ concepts: a.concepts }))
      )
    )
  );

  anonMigrated$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(AuthAction.anonMigrated),
        tap((status) => {
          if (status.state) {
            this.profileService.destroyAnonSession().subscribe();
          }
        })
      ),
    { dispatch: false }
  );
}

@Injectable()
export class AnonMigrationProgressState {
  constructor(private action$: Actions, private state: Store<appStore>) {}

  anonMigrationStep$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        AuthAction.anonCartMigrated,
        AuthAction.anonAddressMigrated,
        AuthAction.anonConceptMigrated,
        AuthAction.anonOrderMigrated
      ),
      switchMap((a) =>
        this.state
          .select(
            (state) =>
              Object.entries(state.auth.anonMigration).filter(
                ([key, val]) => !val
              ).length
          )
          .pipe(
            tap((length) => console.log(length)),
            switchMap((length) =>
              of(AuthAction.anonMigrated({ state: !(length > 0) }))
            )
          )
      )
    )
  );
}

export const AnonEffect = [
  AnonMigrationEffects,
  AnonMigratedEffects,
  AnonMigrationProgressState,
];
