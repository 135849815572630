import { Observable, of, switchMap } from 'rxjs';
import { ProductOptionsFormSetting } from '../product-option.type';
import { Product } from '../../store/product.types';

export const getFormHelpers = (
  product: Observable<Product>
): Observable<ProductOptionsFormSetting[]> => {
  return product.pipe(
    switchMap((product) => {
      const posettings: ProductOptionsFormSetting[] = [];
      product.product_options.forEach((po) => {
        if (po.type == 'radio' || 'selection' || 'dropdown') {
          const existing = posettings.find((e) => e.key == po.key);

          if (existing) {
            existing.options.push({
              value: po.value as string,
              selected: po.selected,
            });
          } else {
            posettings.push({
              key: po.key,
              type: po.userInputType as
                | 'radio'
                | 'selection'
                | 'dropdown'
                | 'text'
                | 'hidden',
              options: [{ value: po.value as string, selected: po.selected }],
            });
          }
        }
      });

      return of(posettings);
    })
  );
};
