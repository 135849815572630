import { Component, Input, inject } from '@angular/core';
import { Color } from '../../../../concepts/store/concept.types';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColorPaletteComponent as ColorPaletteComponentModal } from '../../../../concepts/color-palette/color-palette.component';

@Component({
  selector: 'app-color-palette',
  standalone: true,
  imports: [],
  templateUrl: './color-palette.component.html',
  styleUrl: './color-palette.component.scss',
})
export class ColorPaletteComponent {
  @Input() color_palette: string | Color[];

  private modalService = inject(NgbModal);

  openColorPaletteModal() {
    const coloPaletterRef = this.modalService.open(ColorPaletteComponentModal, {
      size: 'lg',
    });
    const clone = [...(this.color_palette as Color[])];
    coloPaletterRef.componentInstance.colorPalette = clone.sort((a, b) => {
      return a.colorId > b.colorId ? 1 : -1;
    });
  }
}
