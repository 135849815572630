<div class="row">
  <dt class="" i18n>{formItem.key, select, size { size }}:</dt>
  <div
    *ngFor="let option of formItem.options"
    [class]="[
      formItem.options.length % 2 == 0 ? 'col-6' : 'col-4',
      'col-xl-4'
    ]"
  >
    <label
      [class]="[
        'btn',
        'btn-outline-secondary',
        'btn-block',
        'mt-2',
        'w-100',
        option.selected ? 'active' : ''
      ]"
    >
      <input
        type="radio"
        class="btn btn-check"
        [name]="formItem.key"
        [id]="formItem.key + '-' + option.value"
        autocomplete="off"
        [checked]="option.selected"
        (click)="select(option.value)"
      />{{ option.value }}
    </label>
  </div>
</div>
