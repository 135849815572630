import { Component, Input } from '@angular/core';
import { AddressCardComponent } from '../../../common/address-card/address-card.component';

@Component({
  selector: 'app-packeta-address',
  standalone: true,
  imports: [AddressCardComponent],
  template: `
    <app-address-card
      [address]="payload"
      [isSelectible]="false"
      [selected]="false"
    >
    </app-address-card>
  `,
  styleUrl: './packeta-address.component.scss',
})
export class PacketaAddressComponent {
  @Input() payload: any;
}
