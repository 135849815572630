<section class="py-5 text-center container">
  <div class="row py-lg-5">
    <div class="col-lg-6 col-md-8 mx-auto">
      <h1 class="fw-light">Concepts - Admin Panel</h1>
    </div>
  </div>
</section>

<div class="album py-5 bg-body-tertiary">
  <div class="container">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
      <app-concept
        class="d-flex align-self-stretch col-12 col-sm-6 col-md-6 col-lg-4"
        *ngFor="
          let concept of concepts$!
            | async
            | paginate : { itemsPerPage: 12, currentPage: currentPage }
        "
        [concept]="concept"
        [adminContext]="true"
      ></app-concept>
    </div>
  </div>
</div>
<pagination-controls
  (pageChange)="pageChange($event)"
  class="concept-pagination"
></pagination-controls>
