<div class="card rounded-3 mb-4">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-12 fw-bold">
        <h4 i18n>Items:</h4>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center">
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item justify-content-between d-flex row align-items-center text-center pt-4"
          *ngFor="let item of order.order_items"
        >
          <div class="row align-items-center">
            <div class="col-6">
              <div class="row">
                <span class="fw-bolder">
                  {{ item.product.name }} ({{ item.count }}x)</span
                >
              </div>

              <div
                *ngFor="let option of item.item_options | userinput"
                class="row"
              >
                <small i18n
                  >{option.key, select, size { size }} {{ option.value }}</small
                >
              </div>
              <div class="row justify-content-around py-3">
                <ng-container
                  *ngFor="let option of item.item_options | userinput : false"
                >
                  <app-s3-image
                    *ngIf="option.type == 's3' && option.key == 'rendered'"
                    [url]="option.value"
                    [asModalButton]="true"
                    [buttonText]="sourceText"
                  ></app-s3-image>
                  <app-s3-image
                    *ngIf="option.type == 's3' && option.key == 'svg'"
                    [url]="option.value"
                    [asModalButton]="true"
                    [buttonText]="svgText"
                  ></app-s3-image>
                  <app-color-palette
                    *ngIf="option.type == 'color_palette'"
                    [color_palette]="option.value"
                  ></app-color-palette>
                </ng-container>
              </div>
            </div>
            <div class="col-6">
              <span> {{ item.priceSnapshot }}€ </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
