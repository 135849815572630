<div *ngIf="!(authenticated$ | async)" class="pt-2">
  <a (click)="auth.loginWithRedirect()" i18n> Log in </a>
</div>

<div
  *ngIf="authenticated$ | async"
  [ngbPopover]="profilePopoverContent"
  i18n-popverTitle="Profile Popover title"
  popoverTitle="Profile"
>
  <ngx-avatars
    [gravatarId]="(user$ | async)?.email"
    [name]="(user$ | async)?.name"
    size="40"
    bgColor="#FFCC4D"
    fgColor="#000000"
  ></ngx-avatars>
</div>

<ng-template #profilePopoverContent>
  <p>
    <span>{{ (user$ | async)?.name }}</span>
  </p>
  <p>
    <span *ngIf="(user$ | async)?.email != (user$ | async)?.name">{{ (user$ | async)?.email }}</span>
  </p>
  <a class="btn btn-sm btn-secondary" (click)="logout()" i18n>Log out</a>
</ng-template>
