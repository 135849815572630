import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { appStore } from '../../../../app.store';
import { PaymentProvider } from '../../store/order.types';
import { OrderActions } from '../../store/order.action';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PacketaCashOnDeliveryComponent } from '../payment-method/packeta-cash-on-delivery/packeta-cash-on-delivery.component';
import { BankTransferComponent } from '../payment-method/bank-transfer/bank-transfer.component';
import { LocalizerPipe } from '../../../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-payment-provider-accordion-item',
  standalone: true,
  imports: [
    CommonModule,
    NgbAccordionModule,
    PacketaCashOnDeliveryComponent,
    BankTransferComponent,
    LocalizerPipe
  ],
  templateUrl: './payment-provider-accordion-item.component.html',
  styleUrl: './payment-provider-accordion-item.component.scss',
})
export class PaymentProviderAccordionItemComponent {
  constructor(private store: Store<appStore>) {}

  ngOnInit(): void {}

  @Input()
  paymentProvider: PaymentProvider;

  @Input()
  selectedProvider: PaymentProvider | undefined;

  selectPaymentProvider() {
    this.store.dispatch(
      OrderActions.selectPaymentProvider({
        payment_provider: this.paymentProvider,
      })
    );
  }
}
