import { createActionGroup, props } from '@ngrx/store';
import { Product, ProductOption } from './product.types';

export interface ProductOptionUpdate {
  id: number;
  key: string;
  display_type: 'radio' | 'selection' | 'dropdown' | 'text' | 'hidden';
  option_type: 's3' | 'color_palette' | 'enum' | 'string';
  value: any;
}

export const ProductAction = createActionGroup({
  source: 'product',
  events: {
    refreshProducts: props<{ products: Product[] }>(),
    updateProductOption: props<ProductOptionUpdate>(),
    updateProductOptions: props<{ updates: ProductOptionUpdate[] }>(),
    incrementProductCount: props<{
      id: number;
    }>(),
    decrementProductCount: props<{
      id: number;
    }>(),
    recalculateSnapshotPrice: props<{
      id: number;
    }>(),
  },
});
