import { Component, Input, OnInit } from '@angular/core';
import {
  BillingInformation,
  Order,
  PaymentDetails,
} from '../../store/order.types';
import { PacketaCashOnDeliveryComponent } from './packeta-cash-on-delivery/packeta-cash-on-delivery.component';
import { BankTransferComponent } from './bank-transfer/bank-transfer.component';
import { CommonModule } from '@angular/common';
import { Observable, filter, map, switchMap, take } from 'rxjs';
import { appStore } from '../../../../app.store';
import { Store, createSelector, select } from '@ngrx/store';
import { OrderActions } from '../../store/order.action';
import { BillingService } from '../../services/billing.service';
import { faSpinner, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-payment-info',
  standalone: true,
  imports: [
    CommonModule,
    PacketaCashOnDeliveryComponent,
    BankTransferComponent,
    FontAwesomeModule,
  ],
  templateUrl: './payment-info.component.html',
  styleUrl: './payment-info.component.scss',
})
export class PaymentInfoComponent implements OnInit {
  @Input() paymentDetails: PaymentDetails;
  @Input() order: Observable<Order | null>;

  billingInformation = Observable<BillingInformation>;
  faSpinner = faSpinner;
  faFileInvoice = faFileInvoice;

  constructor(
    private store: Store<appStore>,
    private billingService: BillingService
  ) {}

  ngOnInit(): void {
    this.order
      .pipe(
        filter((order) => order !== null),
        take(1)
      )
      .subscribe((order) => {
        this.store.dispatch(
          OrderActions.requestBillingInformation({
            orderId: order!.id as number,
          })
        );

        this.billingService.getBillingDetails(order!.id).subscribe({
          next: (details) => {
            this.store.dispatch(
              OrderActions.loadBillingInformation({
                orderId: order!.id,
                details,
                error: false,
              })
            );
          },
          error: (err) => {
            console.error('Failed to load billing details');
            this.store.dispatch(
              OrderActions.loadBillingInformation({
                orderId: order!.id,
                details: null,
                error: true,
              })
            );
          },
        });
      });
  }
}
