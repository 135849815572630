import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import { ConceptAction } from "../../concepts/store/concepts.action";
import { CommonAction } from "./common.action";
import { AnalyticsService } from "../../analytics.service";


@Injectable()
export class AlertAddedEffect {
    constructor(private action$: Actions, private analyticsService: AnalyticsService) { }

    conceptsRedirect$ = createEffect(
        () =>
            this.action$.pipe(
                ofType(CommonAction.createAlert),
                tap((create) => {
                    this.analyticsService.trackEvent(create.alert.tag, create.alert.message, create.alert.category);
                })
            ),
        { dispatch: false }
    );
}

export const CommonEffects = [AlertAddedEffect];
