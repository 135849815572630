import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
import { appStore } from '../../../app.store';
import { ProductAction } from './products.action';

/**
 * Trigger updateproductoptions with no updates to trigger price recalculation in reducer.
 */
@Injectable()
export class ProductSettingsUpdated {
  constructor(private action$: Actions, private store: Store<appStore>) {}

  increment$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProductAction.incrementProductCount),
      switchMap((increment) =>
        of(ProductAction.recalculateSnapshotPrice({ id: increment.id }))
      )
    )
  );

  decrement$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProductAction.decrementProductCount),
      switchMap((decrement) =>
        of(ProductAction.recalculateSnapshotPrice({ id: decrement.id }))
      )
    )
  );

  optionChanged$ = createEffect(() =>
    this.action$.pipe(
      ofType(ProductAction.updateProductOption),
      switchMap((option) =>
        of(ProductAction.recalculateSnapshotPrice({ id: option.id }))
      )
    )
  );
}

export const ProductEffects = [ProductSettingsUpdated];
