import { createActionGroup, props } from '@ngrx/store';
import {
  Order,
  OrderAddress,
  Country,
  DeliveryProvider,
  PaymentProvider,
  BillingInformation,
} from './order.types';
import { Cart } from '../../cart/store/cart.types';

export const OrderActions = createActionGroup({
  source: 'order',
  events: {
    refreshOrders: props<{ orders: Order[] }>(),
    createOrder: props<{ order: Order }>(),
    createDraft: props<{ cart: Cart | null }>(),
    listAddresses: props<{ addresses: OrderAddress[] }>(),
    addAddress: props<{ address: OrderAddress }>(),
    selectBillingAddress: props<{ address: OrderAddress }>(),
    listCountries: props<{ countries: Country[] }>(),
    listDeliveryProviders: props<{ deliveryProviders: DeliveryProvider[] }>(),
    selectDeliveryProvider: props<{ delivery_provider: DeliveryProvider }>(),
    configureShippingDetails: props<{
      cost: number;
      payload: any;
    }>(),
    listPaymentProviders: props<{ paymentProviders: PaymentProvider[] }>(),
    selectPaymentProvider: props<{ payment_provider: PaymentProvider }>(),
    configurePaymentDetails: props<{
      cost: number;
      payload: any;
    }>(),
    requestBillingInformation: props<{
      orderId: number;
    }>(),
    loadBillingInformation: props<{
      orderId: number;
      details: BillingInformation | null;
      error: boolean;
    }>(),
  },
});
