<div class="modal-header">
  <h4 class="modal-title" i18n>Select your Concept</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div
    class="px-4 py-1"
    *ngFor="let conceptResult of conceptResults$ | async; let last = last"
  >
    <div class="row flex-row align-items-center g-5">
      <div class="col-12 col-lg-6">
        <div *ngIf="conceptResult.rendered">
          <div [hidden]="!loadedConceptResultList.includes(conceptResult.id)">
            <a
              (click)="openPreview(conceptResult.id)"
              *ngIf="conceptResult.rendered"
            >
              <img
                [src]="conceptResult.rendered_thumbnail ?? conceptResult.rendered"
                class="img-fluid"
                (load)="conceptResultImgLoaded(conceptResult.id)"
            /></a>
          </div>
          <div
            [hidden]="loadedConceptResultList.includes(conceptResult.id)"
            class="text-center"
          >
            <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
            <span i18n>Loading Image</span>
          </div>
        </div>
        <!-- <div *ngIf="!conceptResult.waiting" class="text-center">
          <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
        </div> -->
      </div>
      <div class="col-12 col-lg-6">
        <div *ngIf="conceptResult.state == 'created'">
          <span
            class="lead fw-bold py-1"
            i18n="Waiting for render engine to pick up"
            >Waiting</span
          >
          <p class="py-2" i18n>
            Please wait until your image is picked up by the rendering engine
          </p>
          <div class="row text-center">
            <fa-icon [icon]="faHourglassHalf" [animation]="'spin'"></fa-icon>
          </div>
        </div>

        <div *ngIf="conceptResult.state == 'rendering'">
          <span class="lead fw-bold py-1" i18n>Rendering</span>
          <div class="py-2">
            <ngb-progressbar
              class="mb-3"
              type="dark"
              textType="white"
              [value]="conceptResult.progress!"
              [showValue]="true"
            />
          </div>
        </div>
        <div *ngIf="conceptResult.state == 'rendered'">
          <span class="lead fw-bold" i18n>Rendered</span>
          <div class="row text-center text-lg-start pt-2 pb-lg-5">
            <p class="col-6 col-lg-12">
              <span class="fw-bold" i18n>Color count:</span>
              {{ conceptResult.color_palette?.length ?? 0 }}
            </p>
            <p class="col-6 col-lg-12">
              <span class="fw-bold" i18n>Facet count:</span>
              {{ conceptResult.facetCount ?? 0 }}
            </p>
          </div>
          <div class="row">
            <p class="col-12">
              {{ conceptResult.concept_profile.description | xlocalize:conceptResult.concept_profile.descriptionLocale }}
            </p>
          </div>
          <div
            class="d-flex justify-content-around align-items-stretch pt-lg-5"
          >
            <button
              *ngIf="
                conceptResult.color_palette &&
                conceptResult.color_palette.length > 0
              "
              class="btn btn-secondary mx-1"
              (click)="openColorPalette(conceptResult.id)"
              i18n
            >
              View Color Palette
            </button>
            <button
              class="btn btn-secondary mx-1"
              (click)="showPrintable(conceptResult.id)"
              [disabled]="!conceptResult.rendered"
              i18n
            >
              Show Printable
            </button>
            <button
              class="btn btn-secondary mx-1"
              (click)="selectConcept(conceptResult)"
              [disabled]="!conceptResult.rendered"
              i18n
            >
              Select Concept
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!last" class="col-12 border-top"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-dark"
    (click)="activeModal.close('Close click')"
    i18n="Close concept result list"
  >
    Close
  </button>
</div>
