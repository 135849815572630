<div class="card rounded-3 mb-4">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-12 fw-bold">
        <h4 i18n>Billing information:</h4>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center">
      <app-address-card
        [address]="(order | async)!.order!.billing_address!"
        [isSelectible]="false"
        [selected]="false"
      >
      </app-address-card>
    </div>
  </div>
</div>
