<div class="card shadow-sm d-flex flex-grow-1">
  <div class="d-flex card-img-top card-img-wrapper justify-content-center">
    <img
      [hidden]="!loaded"
      class="img-fluid m-auto"
      [src]="concept.source_thumbnail ?? concept.source"
      (load)="onLoad()"
    />
    <span [hidden]="loaded" class="d-flex align-self-center">
      <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
      <span i18n> &nbsp; Loading source image... </span>
    </span>
  </div>

  <div class="card-body">
    <div class="row">
      <span class="fw-bold col-6" i18n>Created on:</span>
      <small class="text-body-secondary col-6 text-end">{{
        concept.created_at | date : "d/M/yy"
      }}</small>
    </div>

    <div class="row" *ngIf="adminContext">
      <span class="fw-bold col-6">Id:</span>
      <small class="text-body-secondary col-6 text-end">{{ concept.id }}</small>
      <span class="fw-bold col-6">Owner:</span>
      <small class="text-body-secondary col-6 text-end">{{
        concept.owner
      }}</small>
      <span class="fw-bold col-6" i18n>Format:</span>
      <small class="text-body-secondary col-6 text-end">{{
        concept.format
      }}</small>
    </div>
  </div>
  <a *ngIf="adminContext" (click)="open()" class="'btn, stretched-link"></a>
  <a *ngIf="!adminContext" (click)="select()" class="'btn, stretched-link"></a>
</div>
