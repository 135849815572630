import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { getBackendUrl } from '../../common/helper/backend.helper';
import { Injectable } from '@angular/core';
import { Concept } from '../store/concept.types';

@Injectable({
  providedIn: 'root',
})
export class AdminConceptsService {
  constructor(public httpClient: HttpClient) {}

  getAllConcepts() {
    return this.httpClient.get<Concept[]>(
      `${getBackendUrl(environment.backendUrl)}/admin/concept`,
      {
        withCredentials: true,
      }
    );
  }
}
