<div *ngIf="!(initialized | async)">
  <div class="container">
    <div class="d-flex align-items-center justify-content-center w-100 p-5">
      <p class="h3">
        <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
        <span i18n>Loading Order...</span>
      </p>
    </div>
  </div>
</div>
<div *ngIf="initialized | async">
  <div *ngIf="orderSummary | async">
    <app-order-summary
      [orderSummary]="orderSummary!"
      [groupItems]="true"
    ></app-order-summary>
    <app-order-items-summary
      [order]="(orderSummary | async)!.order!"
    ></app-order-items-summary>

    <app-billing-info [order]="orderSummary"></app-billing-info>
    <app-shipping-info
      [deliveryDetails]="(orderSummary | async)!.order!.delivery!"
    ></app-shipping-info>
    <app-payment-info
      [paymentDetails]="(orderSummary | async)!.order!.payment!"
      [order]="order"
    ></app-payment-info>
  </div>
  <div *ngIf="!(orderSummary | async)" i18n>Order not found</div>
</div>
