import { Injectable } from '@angular/core';
import { DeliveryProvider, PaymentProvider } from '../store/order.types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { getBackendUrl } from '../../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class FulfillmentService {
  constructor(private httpClient: HttpClient) {}

  listDeliveryProviders() {
    return this.httpClient.get<DeliveryProvider[]>(
      `${getBackendUrl(environment.backendUrl)}/delivery/providers`
    );
  }

  listPaymentProviders() {
    return this.httpClient.get<PaymentProvider[]>(
      `${getBackendUrl(environment.backendUrl)}/payment/providers`
    );
  }
}
