import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-gallery-carousel',
  standalone: true,
  imports: [CommonModule, NgbCarouselModule],
  templateUrl: './product-gallery-carousel.component.html',
  styleUrl: './product-gallery-carousel.component.scss',
})
export class ProductGalleryCarouselComponent {
  @Input() images: string[];

  constructor(config: NgbCarouselConfig) {
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
  }
}
