/// <reference types="@angular/localize" />

import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { appStore } from '../../app.store';
import { Store } from '@ngrx/store';
import { Alert } from '../store/common.types';
import { Observable, debounceTime, filter, from, switchMap, take } from 'rxjs';
import { CommonAction } from '../store/common.action';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FriendlyAlertModalComponent } from '../friendly-alert-modal/friendly-alert-modal.component';

@Component({
  selector: 'app-fixedalert-wrapper',
  standalone: true,
  imports: [CommonModule, NgbAlert],
  templateUrl: './fixedalert-wrapper.component.html',
  styleUrl: './fixedalert-wrapper.component.scss',
})
export class FixedalertWrapperComponent implements OnInit, AfterViewInit {
  alerts: Observable<Alert[]>;

  @ViewChildren('selfClosing') alertComponents: QueryList<NgbAlert>;

  ngAfterViewInit(): void {
    this.alertComponents.changes
      .pipe(debounceTime(10000))
      .subscribe((changes: QueryList<NgbAlert>) => {
        changes.forEach((item) => item.close());
      });
  }

  ngOnInit(): void {
    this.alerts = this.store.select((store) =>
      store.commonModule.alerts.filter((e) => !e.isModal)
    );
    this.store
      .select((store) => store.commonModule.alerts.filter((e) => e.isModal))
      .subscribe((alerts) => {
        alerts.forEach((md) => {
          const mdRef = this.modalService.open(FriendlyAlertModalComponent);
          mdRef.componentInstance.alert = md
        })
      });
  }

  close(alert: Alert) {
    this.store.dispatch(CommonAction.closeAlert({ id: alert.id }));
  }

  constructor(private store: Store<appStore>, private modalService: NgbModal) {}
}
