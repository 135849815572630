import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'app-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './preview.component.html',
  styleUrl: './preview.component.scss',
})
export class PreviewComponent {
  constructor(private http: HttpClient) {}

  activeModal = inject(NgbActiveModal);
  @Input() preview: Observable<string>;
  @Input() downloadable: boolean = false;
  @Input() downloadableFilename: string = 'file';

  downloadImage() {
    this.preview
      .pipe(
        take(1),
        switchMap((url) => this.http.get(url, { responseType: 'blob' })))
      .subscribe((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.downloadableFilename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}
