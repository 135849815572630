import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { ConceptAction } from './concepts.action';
import { Router } from '@angular/router';

@Injectable()
export class ConceptAddedEffect {
  constructor(private action$: Actions, private router: Router) {}

  conceptsRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ConceptAction.createConcept),
        tap((create) => {
          this.router.navigate(
            ['/products', create.concept.concept_format.product_slugs[0]],
            {
              queryParams: { concept_id: create.concept.id },
            }
          );
        })
      ),
    { dispatch: false }
  );
}

@Injectable()
export class ConceptSelectedEffect {
  constructor(private action$: Actions, private router: Router) {}

  redirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ConceptAction.selectConcept),
        tap((action) => {
          this.router.navigate(
            [`/products/${action.concept.concept_format.product_slugs[0]}`],
            { queryParams: { concept_id: action.concept.id } }
          );
        })
      ),
    { dispatch: false }
  );
}

export const ConceptsEffects = [ConceptAddedEffect, ConceptSelectedEffect];
