import { Pipe, PipeTransform } from '@angular/core';
import { OrderItemOption } from '../../eshop/order/store/order.types';

/**
 * Return only the list of user inputs on a list of OrderItemOptions
 */
@Pipe({
  standalone: true,
  name: 'xlocalize',
})
export class LocalizerPipe implements PipeTransform {
  transform(
    name: string,
    locales: { locale: string; value: string }[]
  ): string {
    return locales.find((e) => e.locale == $localize.locale)?.value ?? name;
  }
}
