import { Routes } from '@angular/router';
import { ConceptListComponent } from './concepts/concept-list/concept-list.component';
import { ConceptNewComponent } from './concepts/concept-new/concept-new.component';
import { ProductListComponent } from './eshop/products/product-list/product-list.component';
import { ProductDetailComponent } from './eshop/products/product-detail/product-detail.component';
import { CartComponent } from './eshop/cart/cart.component';
import { OrderCreateComponent } from './eshop/order/order-create/order-create.component';
import { OrderListComponent } from './eshop/order/order-list/order-list.component';
import { OrderDetailComponent } from './eshop/order/order-detail/order-detail.component';
import { AdminConceptListComponent } from './concepts/admin/admin-concept-list/admin-concept-list.component';
import { permissionsGuard } from './auth/permissions.guard';

export const routes: Routes = [
  {
    path: 'admin/concepts',
    component: AdminConceptListComponent,
    canActivate: [permissionsGuard],
    data: { permissions: ['read:concepts'] },
  },
  {
    path: 'concepts',
    component: ConceptListComponent,
  },
  {
    path: 'concepts/new',
    component: ConceptNewComponent,
  },
  {
    path: 'products',
    component: ProductListComponent,
  },
  {
    path: 'products/:id',
    component: ProductDetailComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'order/create',
    component: OrderCreateComponent,
  },
  {
    path: 'order/list',
    component: OrderListComponent,
  },
  {
    path: 'order/detail/:id',
    component: OrderDetailComponent,
  },
];
