import { Component, Input, inject } from '@angular/core';
import { Alert } from '../store/common.types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { appStore } from '../../app.store';
import { Store } from '@ngrx/store';
import { CommonAction } from '../store/common.action';

@Component({
  selector: 'app-friendly-alert-modal',
  standalone: true,
  imports: [],
  templateUrl: './friendly-alert-modal.component.html',
  styleUrl: './friendly-alert-modal.component.scss',
})
export class FriendlyAlertModalComponent {
  constructor(private store: Store<appStore>) {}

  activeModal = inject(NgbActiveModal);

  @Input() alert: Alert;

  close() {
    this.store.dispatch(CommonAction.closeAlert({ id: this.alert.id }));
    this.activeModal.close();
  }
}
