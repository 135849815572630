import { Component, Input } from '@angular/core';
import { Order } from '../../../store/order.types';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bank-transfer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bank-transfer.component.html',
  styleUrl: './bank-transfer.component.scss',
})
export class BankTransferComponent {
  @Input() payload: any;
  @Input() order: Observable<Order | null>;
}
