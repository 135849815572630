import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConceptsService } from '../../../concepts/concepts.service';
import { Store } from '@ngrx/store';
import { CartAction } from './cart.actions';
import { EMPTY, map, of, switchMap, tap } from 'rxjs';
import { appStore } from '../../../app.store';
import { ConceptAction } from '../../../concepts/store/concepts.action';

@Injectable()
export class CartRefreshedEffects {
  constructor(
    private action$: Actions,
    private conceptService: ConceptsService,
    private store: Store<appStore>
  ) {}

  cartRefreshed$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartAction.refreshCart),
      switchMap((cart) =>
        this.store.select((state) => state.conceptModule.initialized)
      ),
      switchMap((initialized) => {
        if (!initialized) {
          return this.conceptService
            .listConcepts()
            .pipe(
              map((concepts) => ConceptAction.refreshConcepts({ concepts }))
            );
        } else {
          return EMPTY;
        }
      })
    )
  );
}


export const CartEffect = [CartRefreshedEffects]