import { Component, Input, OnInit } from '@angular/core';
import { OrderSummary } from '../utils';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-order-summary',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss',
})
export class OrderSummaryComponent implements OnInit {
  @Input() orderSummary: Observable<OrderSummary | null>;
  @Input() groupItems: boolean = false;

  goodsValue: Observable<number>;

  ngOnInit(): void {
    this.goodsValue = this.orderSummary.pipe(
      filter((orderSummary) => orderSummary !== null),
      map(
        (orderSummary) =>
          orderSummary?.items.reduce((prev, cur) => cur.price + prev, 0) ?? 0
      )
    );
  }
}
