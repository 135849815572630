<div class="modal-header">
    <h4 class="modal-title" i18n>Alert!</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    {{ alert.message }}
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="close()"
      i18n="close friendly alert modal"
    >
      Close
    </button>
  </div>
  