import { Component, CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCartShopping,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { CartService } from '../cart/cart.service';
import { Observable, Subscription, map, of, switchMap, take, tap } from 'rxjs';
import { Cart } from '../cart/store/cart.types';
import { appStore } from '../../app.store';
import { Store } from '@ngrx/store';
import { CartAction } from '../cart/store/cart.actions';
import { CommonModule } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-cart-icon-popover',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [FontAwesomeModule, CommonModule, NgbPopoverModule, RouterModule],
  templateUrl: './cart-icon-popover.component.html',
  styleUrl: './cart-icon-popover.component.scss',
})
export class CartIconModalComponent implements OnInit, OnDestroy {
  faCartShopping = faCartShopping;
  faCircleNotch = faCircleNotch;

  initialized: Observable<boolean>;
  cart: Observable<Cart>;
  itemCount: Observable<number>;

  authWatcherSubscription: Subscription;

  constructor(
    private cartService: CartService,
    private store: Store<appStore>,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.cart = this.store.select(
      (state) => state.eshopCartModule.cart
    ) as Observable<Cart>;
    this.initialized = this.store.select(
      (state) => state.eshopCartModule.initialized
    );

    this.itemCount = this.store
      .select((state) => state.eshopCartModule.cart)
      .pipe(
        map((cart) => {
          return cart?.cart_items.reduce((prev, cur) => {
            return prev + cur.count;
          }, 0) as number;
        })
      );

    this.authWatcherSubscription = this.auth.isAuthenticated$
      .pipe(
        switchMap((authenticated) => {
          return this.cartService.getCart();
        }),
        tap((cart) => {
          this.store.dispatch(CartAction.refreshCart({ cart }));
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.authWatcherSubscription.unsubscribe();
  }
}
