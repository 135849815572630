import { createReducer, on } from '@ngrx/store';
import { ConceptAction } from './concepts.action';
import { Concept, ConceptFormat, ConceptResult } from './concept.types';

export interface conceptModuleState {
  concepts: Concept[];
  selectedConceptResult?: ConceptResult;
  formats: ConceptFormat[],
  initialized: boolean;
}

export const initialState: conceptModuleState = {
  concepts: [],
  formats: [],
  initialized: false,
};

export const conceptReducer = createReducer(
  initialState,
  on(ConceptAction.createConcept, (state, { concept }) => {
    return {
      ...state,
      concepts: [concept, ...state.concepts],
    };
  }),
  on(ConceptAction.updateConcept, (state, { concept }) => {
    return {
      ...state,
      concepts: state.concepts.map((e) => (e.id == concept.id ? concept : e)),
    };
  }),
  on(ConceptAction.deleteConcept, (state, { id }) => {
    return {
      ...state,
      concepts: state.concepts.filter((e) => e.id != id),
    };
  }),
  on(ConceptAction.refreshConcepts, (state, { concepts }) => {
    return {
      ...state,
      initialized: true,
      concepts,
    };
  }),
  on(
    ConceptAction.updateConceptResult,
    (state, { conceptId, concept_result }) => {
      return {
        ...state,
        concepts: state.concepts.map((e) =>
          e.id == conceptId
            ? {
              ...e,
              concept_results: e.concept_results.map((cr) =>
                cr.id == concept_result.id ? concept_result : cr
              ),
            }
            : e
        ),
      };
    }
  ),
  on(
    ConceptAction.updateConceptResultProgress,
    (state, { conceptId, concept_result_id, progress }) => {
      return {
        ...state,
        concepts: state.concepts.map((e) =>
          e.id == conceptId
            ? {
              ...e,
              concept_results: e.concept_results.map((cr) =>
                cr.id == concept_result_id
                  ? {
                    ...cr,
                    state: 'rendering',
                    progress,
                  }
                  : cr
              ),
            }
            : e
        ),
      };
    }
  ),
  on(ConceptAction.selectConceptResult, (state, { concept_result }) => {
    return {
      ...state,
      selectedConceptResult: concept_result,
    };
  }),
  on(ConceptAction.updateThumbnail, (state, update) => {
    if (update.meta.resource == 'concept') {
      return {
        ...state,
        concepts: state.concepts.map((c) =>
          c.id == update.meta.id
            ? {
              ...c,
              source_thumbnail: update.path,
            }
            : c
        ),
      };
    }

    if (update.meta.resource == 'concept_result') {
      return {
        ...state,
        concepts: state.concepts.map((c) => {
          return {
            ...c,
            concept_results: c.concept_results.map((cr) =>
              cr.id == update.meta.id
                ? { ...cr, rendered_thumbnail: update.path }
                : cr
            ),
          };
        }),
      };
    }

    return state;
  }),
  on(ConceptAction.listFormats, (state, { formats }) => {
    return {
      ...state,
      formats
    }
  })
);
