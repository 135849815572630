import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { OrderActions } from './order.action';
import { CartService } from '../../cart/cart.service';
import { CartAction } from '../../cart/store/cart.actions';

@Injectable()
export class OrderDraftCreatedEffect {
  constructor(private action$: Actions, private router: Router) {}

  draftRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderActions.createDraft),
        tap((payload) => {
          this.router.navigate(['/order', 'create']);
        })
      ),
    { dispatch: false }
  );
}

@Injectable()
export class AddressAddedEffect {
  constructor(private action$: Actions) {}

  addressSelect$ = createEffect(() =>
    this.action$.pipe(
      ofType(OrderActions.addAddress),
      switchMap((address) =>
        of(OrderActions.selectBillingAddress({ address: address.address }))
      )
    )
  );
}

@Injectable()
export class OrderCreatedEffect {
  constructor(
    private action$: Actions,
    private router: Router,
    private cartService: CartService
  ) {}

  orderCreate$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderActions.createOrder),
        tap((payload) => {
          this.router.navigate(['/order', 'list']);
        })
      ),
    { dispatch: false }
  );

  cleanCart$ = createEffect(() =>
    this.action$.pipe(
      ofType(OrderActions.createOrder),
      switchMap((_) => this.cartService.cleanCart()),
      switchMap((cart) => of(CartAction.refreshCart({ cart })))
    )
  );
}

export const OrderEffect = [
  OrderDraftCreatedEffect,
  AddressAddedEffect,
  OrderCreatedEffect,
];
