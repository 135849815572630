<div class="card rounded-3 mb-4">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-12 fw-bold">
        <h4 i18n>Payment information:</h4>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center">
      <ng-container [ngSwitch]="paymentDetails.provider.component_type">
        <div *ngSwitchCase="'bank_transfer'">
          <app-bank-transfer
            [payload]="paymentDetails.payload"
            [order]="order"
          ></app-bank-transfer>
        </div>
        <div *ngSwitchCase="'packeta_cash_on_delivery'">
          <app-packeta-cash-on-delivery
            [payload]="paymentDetails.payload"
          ></app-packeta-cash-on-delivery>
        </div>
      </ng-container>
    </div>

    <div *ngIf="(order | async)!.billing!">
      <div *ngIf="(order | async)!.billing!.loading">
        <span i18n>Loading billing details...</span>
      </div>

      <div *ngIf="!(order | async)!.billing!.loading">
        <div *ngIf="!(order | async)!.billing!.error">
          <fa-icon [icon]="faFileInvoice" class="px-5"></fa-icon>
          <span i18n="Click here to view your invoice - Click">Click </span
          ><a
            i18n="Click here to view your invoice - here"
            [href]="(order | async)!.billing!.details!.invoiceUrl"
            target="_blank"
            >here</a
          ><span i18n="Click here to view your invoice - to view your invoice">
            to view your invoice</span
          >
        </div>

        <div *ngIf="(order | async)!.billing!.error" class="text-danger" i18n>
          Failed to load invoice
        </div>
      </div>
    </div>
  </div>
</div>
