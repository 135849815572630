import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, addCartItemDto } from './store/cart.types';
import { environment } from '../../../environments/environment';
import { getBackendUrl } from '../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private httpClient: HttpClient) {}

  getCart() {
    return this.httpClient.get<Cart>(
      `${getBackendUrl(environment.backendUrl)}/cart`,
      {
        withCredentials: true,
      }
    );
  }

  addCartItem(item: addCartItemDto) {
    return this.httpClient.post<Cart>(
      `${getBackendUrl(environment.backendUrl)}/cart/item`,
      item,
      {
        withCredentials: true,
      }
    );
  }

  deleteCartItem(id: number) {
    return this.httpClient.delete<Cart>(
      `${getBackendUrl(environment.backendUrl)}/cart/item/${id}`,
      { withCredentials: true }
    );
  }

  cleanCart() {
    return this.httpClient.delete<Cart>(
      `${getBackendUrl(environment.backendUrl)}/cart`,
      {
        withCredentials: true,
      }
    );
  }

  migrateCart() {
    return this.httpClient.post<Cart>(
      `${getBackendUrl(environment.backendUrl)}/cart/migrate`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
