import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { appStore } from '../app.store';
import { filter, map, of, switchMap, tap } from 'rxjs';

export const permissionsGuard: CanActivateFn = (route, state) => {
  const requiredPermissions = (route.data['permissions'] as string[]) ?? [];
  const store = inject(Store<appStore>);
  const userPermissions = store
    .select((state: appStore) => state.auth)
    .pipe(
      filter((auth) => auth.authLoaded),
      map((auth) => auth.permissions)
    );

  return userPermissions.pipe(
    switchMap((userPermissions) =>
      of(
        requiredPermissions.filter((perm) => userPermissions.includes(perm))
          .length == requiredPermissions.length
      )
    )
  );
};
