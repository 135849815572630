import { Pipe, PipeTransform } from '@angular/core';
import { OrderItemOption } from '../../eshop/order/store/order.types';

/**
 * Return only the list of user inputs on a list of OrderItemOptions
 */
@Pipe({
  standalone: true,
  name: 'userinput',
})
export class UserInputPipe implements PipeTransform {
  transform(
    items: OrderItemOption[],
    truthiness: boolean = true
  ): OrderItemOption[] {
    return items.filter((e) => (truthiness ? e.isUserInput : !e.isUserInput));
  }
}
