<div class="row" *ngIf="(initialized | async); else uninitialized">
  <div *ngIf="(product | async); else notFound">
    <div [ngSwitch]="(product | async)!.type">
      <app-paint-by-numbers *ngSwitchCase="PAINT_BY_NUMBERS"></app-paint-by-numbers>
    </div>
  </div>
</div>

<ng-template #notFound>
  <div class="p-5 text-center bg-body-tertiary">
    <div class="container py-5">
      <h1 class="text-body-emphasis" i18n>Product not found or Archived</h1>
      <p class="col-lg-8 mx-auto lead" i18n>
        The chosen concept was created for a product which is no longer available or was archived. Plese follow to the <a
          [routerLink]="['/products']" class="link-secondary">products</a> page for the current available products
      </p>
    </div>
  </div>
</ng-template>

<ng-template #uninitialized i18n>
  Loading ...
</ng-template>