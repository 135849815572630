<div
  ngbAccordionItem
  [destroyOnHide]="true"
  [collapsed]="selectedProvider?.id !== deliveryProvider.id"
  [disabled]="selectedProvider?.id === deliveryProvider.id"
>
  <div ngbAccordionHeader class="accordion-button custom-header">
    <button
      ngbAccordionToggle
      class="p-0 btn btn-link container-fluid text-start ps-0 link-dark link-underline link-underline-opacity-0"
      (click)="selectDeliveryProvider()"
    >
      <div class="flex-row d-flex justify-content-between">
        <div class="col-6">
          <span>
            {{ deliveryProvider.name | xlocalize:deliveryProvider.locale }}
          </span>
        </div>
        <div class="col-3">
          <img
            [src]="deliveryProvider.icon_url"
            class="img-fluid delivery-provider-icon"
          />
        </div>
      </div>
    </button>
  </div>

  <div ngbAccordionCollapse>
    <div ngbAccordionBody>
      <ng-template>
        <ng-container [ngSwitch]="deliveryProvider.component_type">
          <div *ngSwitchCase="'packeta_pickup_selector'">
            <app-packeta-pickup
              [deliveryProvider]="deliveryProvider"
            ></app-packeta-pickup>
          </div>
          <div *ngSwitchCase="'packeta_address_selector'">
            <app-packeta-address
              [deliveryProvider]="deliveryProvider"
            ></app-packeta-address>
          </div>
          <div *ngSwitchDefault>I don't know what to do here</div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
