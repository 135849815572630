<div class="modal-header">
  <h4 class="modal-title" i18n>Welcome</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <h4 class="fw-bold">
    <span i18n> Guest user activity detected on this browser </span
    ><span class="text-danger">!</span>
  </h4>
  <p class="col-12 col-md-10" i18n>
    We have noticed that you have created a few resources while you were
    unauthenticated on this browser. Do you want to move these data under the
    current logged in user?
  </p>
  <p i18n>The following content will be moved:</p>

  <ul>
    <li *ngIf="(anonContent | async).cartUsed" i18n>
      Cart content
      <span
        *ngIf="(migrating | async) && !(migrationState | async).cartMigrated"
        ><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span
      ><span *ngIf="(migrationState | async).cartMigrated"
        ><fa-icon [icon]="faCheck"></fa-icon
      ></span>
    </li>
    <li *ngIf="(anonContent | async).conceptCreated" i18n>
      Concepts
      <span
        *ngIf="(migrating | async) && !(migrationState | async).conceptMigrated"
        ><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span
      ><span *ngIf="(migrationState | async).conceptMigrated"
        ><fa-icon [icon]="faCheck"></fa-icon
      ></span>
    </li>
    <li *ngIf="(anonContent | async).addressAdded" i18n>
      Billing addresses
      <span
        *ngIf="(migrating | async) && !(migrationState | async).addressMigrated"
        ><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span
      ><span *ngIf="(migrationState | async).addressMigrated"
        ><fa-icon [icon]="faCheck"></fa-icon
      ></span>
    </li>
    <li *ngIf="(anonContent | async).orderCreated" i18n>
      Orders
      <span
        *ngIf="(migrating | async) && !(migrationState | async).orderMigrated"
        ><fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon></span
      ><span *ngIf="(migrationState | async).orderMigrated"
        ><fa-icon [icon]="faCheck"></fa-icon
      ></span>
    </li>
  </ul>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="migrate()"
    [disabled]="(migrating | async) || migrated"
    i18n="move unauthenticated content"
  >
    Move
  </button>
</div>
