import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

export interface FileStorage {
  [id: string]: string | ArrayBuffer | Blob;
}

@Injectable({
  providedIn: 'root',
})
export class PrivateFileStorageService {
  private files = new BehaviorSubject<FileStorage>({});

  setFile(id: string, file: string | ArrayBuffer | Blob) {
    this.files.next({
      ...this.files.getValue(),
      [id]: file,
    });
  }

  // We needed to do some hackity hacks with casting so compiler is happy on loading these files on templates
  getFile(id: string | null): Observable<string | ArrayBuffer | Blob> {
    return this.files.pipe(map((files) => (id as string in files ? files[id as string] : "")));
  }

  constructor() {}
}
