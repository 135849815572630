import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { OrderItemsSummaryComponent } from './order-items-summary/order-items-summary.component';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { Observable, filter, map, switchMap, take, tap } from 'rxjs';
import { Order } from '../store/order.types';
import { appStore } from '../../../app.store';
import { Store, createSelector, select } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { OrderSummaryComponent } from '../common/order-summary/order-summary.component';
import { OrderSummary, orderSummaryReducer } from '../common/utils';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { ShippingInfoComponent } from './shipping-info/shipping-info.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { OrderService } from '../services/order.service';
import { OrderActions } from '../store/order.action';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-order-detail',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FontAwesomeModule,
    OrderItemsSummaryComponent,
    RouterModule,
    CommonModule,
    OrderSummaryComponent,
    BillingInfoComponent,
    ShippingInfoComponent,
    PaymentInfoComponent,
  ],
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss',
})
export class OrderDetailComponent implements OnInit {
  orderSummary: Observable<OrderSummary | null>;
  order: Observable<Order | null>;
  initialized: Observable<boolean>;

  faSpinner = faSpinner;

  orderSummarySelector = createSelector(
    (state: appStore) =>
      state.eshopOrderModule.orders.find(
        (e) => e.id == this.route.snapshot.params['id']
      ) ?? null,
    orderSummaryReducer
  );

  orderSelector = createSelector(
    (state: appStore) =>
      state.eshopOrderModule.orders.find(
        (e) => e.id == this.route.snapshot.params['id']
      ) ?? null,
    (order) => order
  );

  constructor(
    private route: ActivatedRoute,
    private store: Store<appStore>,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.initialized = this.store.select(
      (state) => state.eshopOrderModule.ordersInitialized
    );

    this.orderSummary = this.store.select(this.orderSummarySelector);
    this.order = this.store.select(this.orderSelector);

    this.store
      .select((store) => store.eshopOrderModule)
      .pipe(
        take(1),
        tap((state) => {
          if (!state.ordersInitialized) {
            this.orderService.listOrders().subscribe((orders) => {
              this.store.dispatch(OrderActions.refreshOrders({ orders }));
            });
          }
        })
      )
      .subscribe();
  }
}
