<div class="bg-body-tertiary main-body">
  <div *ngIf="isAuth0Loading$ | async; else auth0Loaded">
    <div class="container-lg">
      <div class="d-flex align-items-center justify-content-center w-100 p-5">
        <p class="h3">
          <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
          <span i18n>Loading Page...</span>
        </p>
      </div>
    </div>
  </div>
  <ng-template #auth0Loaded>
    <!-- body -->
    <div class="container-lg">
      <!-- Navbar -->
      <nav
        class="navbar navbar-expand-md bg-body-tertiary rounded mb-4 border-bottom"
      >
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            (click)="isMenuCollapsed = !isMenuCollapsed"
            type="button"
          >
            &#9776;
          </button>

          <div
            class="collapse navbar-collapse d-md-flex"
            [ngbCollapse]="isMenuCollapsed"
          >
            <!-- LOGO -->
            <a class="navbar-brand col-md-3 col-sm-2">
              <img
                class="img-fluid py-2 pt-3 pt-md-0"
                src="assets/logo_transparent.png"
                style="max-width: 100px"
              />
            </a>

            <!-- MenuItmes -->
            <ul class="navbar-nav col-md-6 justify-content-md-center">
              <li class="nav-item">
                <a
                  i18n
                  class="nav-link"
                  [routerLink]="['/products']"
                  (click)="isMenuCollapsed = true"
                  >Products</a
                >
              </li>
              <li class="nav-item">
                <a
                  i18n
                  class="nav-link"
                  [routerLink]="['/concepts']"
                  (click)="isMenuCollapsed = true"
                  >Concepts</a
                >
              </li>
              <li class="nav-item">
                <a
                  i18n
                  class="nav-link"
                  [routerLink]="['/order', 'list']"
                  (click)="isMenuCollapsed = true"
                  >Orders</a
                >
              </li>
              <li
                class="nav-item dropdown"
                #dropdownAdminMenu
                [hasPermissions]="['read:concepts']"
              >
                <a
                  class="nav-link dropdown-toggle"
                  role="button"
                  (click)="isAdminPanelCollapsed = !isAdminPanelCollapsed"
                  >Admin</a
                >
                <ul
                  [class]="[
                    'dropdown-menu',
                    isAdminPanelCollapsed ? '' : 'show'
                  ]"
                >
                  <li>
                    <a
                      class="dropdown-item"
                      [routerLink]="['/admin', 'concepts']"
                      (click)="isAdminPanelCollapsed = true"
                    >
                      Concepts
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      [routerLink]="['/admin', 'orders']"
                      (click)="isAdminPanelCollapsed = true"
                    >
                      Orders
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <!-- Just a divider, due to flex only visible when small and expanded -->
            <div class="border-bottom mb-3 mt-2"></div>

            <!-- on large screen -->
            <div class="d-none d-md-flex col-md-3 justify-content-md-end px-2">
              <app-cart-icon-popover
                class="nav-link mx-2 pt-2"
                (click)="isMenuCollapsed = true"
              ></app-cart-icon-popover>
              <app-login-button
                class="nav-link mx-2"
                (click)="isMenuCollapsed = true"
              ></app-login-button>
            </div>
          </div>

          <!-- on small screen - collapsible -->
          <div class="d-flex d-md-none justify-content-md-end">
            <app-cart-icon-popover
              class="nav-link mx-2 pt-2"
              (click)="isMenuCollapsed = true"
            ></app-cart-icon-popover>
            <app-login-button class="nav-link mx-2"></app-login-button>
          </div>
        </div>
      </nav>
      <!-- end navbar -->
      <div class="outlet-body">
        <ngx-cookie-consent></ngx-cookie-consent>
        <app-frontpage></app-frontpage>
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- end body -->

    <!-- footer -->
    <div class="container">
      <footer class="mt-5">
        <div class="pt-3">
          <ul class="nav justify-content-center mb-3">
            <li class="nav-item">
              <a
                i18n
                [routerLink]="['/']"
                class="nav-link px-2 text-body-secondary"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                i18n
                href="https://www.numeroart.sk/cookies/"
                class="nav-link px-2 text-body-secondary"
                target="_blank"
                >Cookie Policy</a
              >
            </li>
            <li class="nav-item">
              <a
                i18n
                href="{{ contact_url }}"
                class="nav-link px-2 text-body-secondary"
                >Contact</a
              >
            </li>
          </ul>
        </div>

        <div
          class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top"
        >
          <div class="col-md-4 d-flex align-items-center">
            <a
              [routerLink]="['/']"
              class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
            >
            </a>
            <span class="mb-3 mb-md-0 text-body-secondary"
              >&copy; 2024 TboTech s.r.o.</span
            >
          </div>

          <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3">
              <fa-icon
                class="text-body-secondary"
                [icon]="faInstagram"
              ></fa-icon>
            </li>
            <li class="ms-3">
              <fa-icon
                class="text-body-secondary"
                [icon]="faFacebook"
              ></fa-icon>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </ng-template>
</div>
<app-fixedalert-wrapper></app-fixedalert-wrapper>
