import { Component, Input } from '@angular/core';
import { DeliveryDetails } from '../../store/order.types';
import { PacketaAddressComponent } from './packeta-address/packeta-address.component';
import { PacketaPickupComponent } from './packeta-pickup/packeta-pickup.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-shipping-info',
  standalone: true,
  imports: [PacketaAddressComponent, PacketaPickupComponent, CommonModule],
  templateUrl: './shipping-info.component.html',
  styleUrl: './shipping-info.component.scss',
})
export class ShippingInfoComponent {
  @Input() deliveryDetails: DeliveryDetails;
}
