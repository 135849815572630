import { Order, OrderItem } from '../store/order.types';

export interface OrderSummary {
  items: { name: string; price: number; count: number }[];
  shippingFee: number;
  paymentFee: number;
  total: number;
  order: Partial<Order> | null;
  order_note: string;
}

export const orderSummaryReducer = (order: Partial<Order> | null) => {
  return {
    items: ((order?.order_items ?? []) as OrderItem[]).map((e) => {
      return {
        name: e.product.name,
        price: e.priceSnapshot,
        count: e.count,
      };
    }),
    shippingFee: order?.delivery?.cost ?? 0,
    paymentFee: order?.payment?.cost ?? 0,
    total:
      (order?.order_items?.reduce((prev, cur) => {
        return prev + cur.priceSnapshot;
      }, 0) ?? 0) +
      (order?.delivery?.cost! ?? 0) +
      (order?.payment?.cost! ?? 0),
    order,
    order_note: order?.order_note ?? '',
  };
};
