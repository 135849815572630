<div
  ngbAccordionItem
  [destroyOnHide]="true"
  [collapsed]="selectedProvider?.id !== paymentProvider.id"
  [disabled]="selectedProvider?.id === paymentProvider.id"
>
  <div ngbAccordionHeader class="accordion-button custom-header">
    <button
      ngbAccordionToggle
      class="p-0 btn btn-link container-fluid text-start ps-0 link-dark link-underline link-underline-opacity-0"
      (click)="selectPaymentProvider()"
    >
      <div class="flex-row d-flex justify-content-between">
        <div class="col-6">
          <span>
            {{ paymentProvider.name | xlocalize:paymentProvider.locale }}
          </span>
        </div>
        <div class="col-3">
          <img
            [src]="paymentProvider.icon_url"
            class="img-fluid payment-provider-icon"
          />
        </div>
      </div>
    </button>
  </div>

  <div ngbAccordionCollapse>
    <div ngbAccordionBody>
      <ng-template>
        <ng-container [ngSwitch]="paymentProvider.component_type">
          <div *ngSwitchCase="'bank_transfer'">
            <app-bank-transfer
              [paymentProvider]="paymentProvider"
            ></app-bank-transfer>
          </div>
          <div *ngSwitchCase="'packeta_cash_on_delivery'">
            <app-packeta-cash-on-delivery
              [paymentProvider]="paymentProvider"
            ></app-packeta-cash-on-delivery>
          </div>
          <div *ngSwitchDefault>I don't know what to do here</div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
