import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { provideStore } from '@ngrx/store';
import { appStoreReducers } from './app.store';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideEffects } from '@ngrx/effects';
import { ConceptsEffects } from './concepts/store/concept.effects';
import { SocketIoModule } from 'ngx-socket-io';
import { ProductEffects } from './eshop/products/store/product.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CartEffect } from './eshop/cart/store/cart.effects';
import { OrderEffect } from './eshop/order/store/order.effects';

import { environment } from '../environments/environment';
import { NgxCookieConsentModule } from '@localia/ngx-cookie-consent';
import { getBackendUrl } from './common/helper/backend.helper';
import { AnonEffect } from './auth/store/auth.effects';
import { lang_sk } from './common/cookies/sk.language';
import { CommonEffects } from './common/store/common.effects';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      AuthModule.forRoot({
        ...environment.auth0,
        httpInterceptor: {
          allowedList: [
            {
              uri: `${getBackendUrl(environment.backendUrl)}/profile/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/admin/*`,
              allowAnonymous: false,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/concept`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/concept/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/cart`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/cart/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/addresses`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/addresses/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/order`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/order/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/billing/*`,
              allowAnonymous: true,
            },
            {
              uri: `${getBackendUrl(environment.backendUrl)}/`,
              allowAnonymous: true,
            },
          ],
        },
      }),
      SocketIoModule.forRoot({
        url: `${getBackendUrl(environment.backendUrl)}`,
        options: {
          withCredentials: true,
          transports: ['websocket'],
        },
      }),
      NgxCookieConsentModule.forRoot({
        availableLanguages: ['en'],
        defaultLanguage: 'sk',
        customLanguage: lang_sk,
        showLanguageSwitcher: true,
        showFunctionalCookies: false,
        showOtherTools: false,
        showMarketingCookies: false,
        imprintUrl: {
          en: 'https://www.numeroart.com/contact/',
          sk: 'https://www.numeroart.sk/kontakt/',
        },
        privacyPolicyUrl: {
          en: 'https://www.numeroart.sk/gdpr/',
          sk: 'https://www.numeroart.sk/gdpr/',
        },
      })
    ),
    provideStore(appStoreReducers),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    provideEffects([
      ...CommonEffects,
      ...ConceptsEffects,
      ...ProductEffects,
      ...CartEffect,
      ...OrderEffect,
      ...AnonEffect,
    ]),
    provideHttpClient(withInterceptorsFromDi()),
    provideStoreDevtools(),
  ],
};
