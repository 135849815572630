import { createReducer, on } from '@ngrx/store';
import { Product } from './product.types';
import { ProductAction, ProductOptionUpdate } from './products.action';
import { ConceptAction } from '../../../concepts/store/concepts.action';

export interface eshopProductModuleState {
  products: Product[];
  initialized: boolean;
}

export const initialState: eshopProductModuleState = {
  products: [],
  initialized: false,
};

export const productOptionUpdateFn = (
  state: eshopProductModuleState,
  { id, key, display_type, value }: ProductOptionUpdate
) => {
  return {
    ...state,
    products: state.products.map((pr) =>
      pr.id == id
        ? {
            ...pr,
            product_options: pr.product_options.map((po) =>
              po.key == key
                ? {
                    ...po,
                    selected: ['radio', 'dropdown', 'selection'].includes(
                      display_type
                    )
                      ? po.value == value
                      : false,
                    value: !['radio', 'dropdown', 'selection'].includes(
                      display_type
                    )
                      ? value
                      : po.value,
                  }
                : po
            ),
          }
        : pr
    ),
  };
};

export const eshopProductReducer = createReducer(
  initialState,
  on(ProductAction.refreshProducts, (state, { products }) => {
    return {
      ...state,
      initialized: true,
      products: products.map((prod) => {
        return {
          ...prod,
          count: 1,
          calculatedPrice: prod.price,
        };
      }),
    };
  }),
  on(ProductAction.updateProductOption, productOptionUpdateFn),
  on(ProductAction.updateProductOptions, (state, { updates }) => {
    return updates.reduce(
      (prev, cur) => productOptionUpdateFn(prev, cur),
      state
    );
  }),
  on(ProductAction.recalculateSnapshotPrice, (state, { id }) => {
    return {
      ...state,
      products: state.products.map((product) =>
        product.id == id
          ? {
              ...product,
              calculatedPrice:
                (product.product_options
                  .filter((e) => e.selected)
                  .reduce((prev, cur) => {
                    return prev + (cur.priceModifier as number);
                  }, product.price) as number) * product.count,
            }
          : product
      ),
    };
  }),
  on(ConceptAction.selectConceptResult, (state, { concept_result }) => {
    const paintByNumbersEnrichFields = ['rendered', 'svg'];

    return state.initialized
      ? {
          ...state,
          products: state.products.map((prod) =>
            prod.slug == 'paint-by-numbers'
              ? {
                  ...prod,
                  product_options: prod.product_options.map((po) =>
                    paintByNumbersEnrichFields.includes(po.key)
                      ? {
                          ...po,
                          value: concept_result[
                            po.key as keyof typeof concept_result
                          ] as any,
                        }
                      : po
                  ),
                }
              : prod
          ),
        }
      : {
          ...state,
        };
  }),
  on(ProductAction.incrementProductCount, (state, { id }) => {
    return {
      ...state,
      products: state.products.map((prod) =>
        prod.id == id ? { ...prod, count: prod.count + 1 } : prod
      ),
    };
  }),
  on(ProductAction.decrementProductCount, (state, { id }) => {
    return {
      ...state,
      products: state.products.map((prod) =>
        prod.id == id
          ? { ...prod, count: prod.count - 1 > 0 ? prod.count - 1 : 1 }
          : prod
      ),
    };
  })
);
