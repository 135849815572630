<div class="card shadow-sm d-flex flex-grow-1">
  <div class="d-flex card-img-top card-img-wrapper justify-content-center">
    <svg
      class="bd-placeholder-img card-img-top"
      width="100%"
      height="250px"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Placeholder"
      preserveAspectRatio="xMidYMid slice"
      focusable="false"
    >
      <title>Placeholder</title>
      <rect width="100%" height="100%" fill="#e3e3e3"></rect>
    </svg>
  </div>

  <div class="card-body">
    <div class="row placeholder-glow justify-content-between">
      <span class="fw-bold col-4 placeholder" i18n></span>
      <small class="text-body-secondary col-4 text-end placeholder"></small>
    </div>
  </div>
</div>
