import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { DeliveryDetails, DeliveryProvider } from '../../../store/order.types';
import { Observable, filter, of, switchMap } from 'rxjs';
import { appStore } from '../../../../../app.store';
import { Store, createSelector, select } from '@ngrx/store';
import { OrderActions } from '../../../store/order.action';

declare var Packeta: any;

export interface PacketaPickupDetails {
  photos: {
    thumbnail: string;
    normal: string;
  }[];
  place: string;
  name: string;
  country: string;
  city: string;
  street: string;
}

@Component({
  selector: 'app-packeta-pickup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './packeta-pickup.component.html',
  styleUrl: './packeta-pickup.component.scss',
})
export class PacketaPickupComponent implements OnInit {
  @Input()
  deliveryProvider: DeliveryProvider;

  deliverySettings: Observable<DeliveryDetails>;
  firstThreeImages: Observable<string[]>;
  initialized: Observable<boolean>;

  constructor(private store: Store<appStore>) {}

  getShippingPrice(country: PacketaPickupDetails | undefined) {
    if (!country) {
      return 0;
    }
    const c = this.deliveryProvider.delivery_country_price.find(
      (e) =>
        country.country == e.country.name ||
        e.country.additionalIdentifiers.includes(country.country)
    );
    if (!c) {
      return 0;
    } else {
      return c.cost;
    }
  }

  packetaWidgetScriptElement: HTMLScriptElement;

  ngOnInit() {
    this.packetaWidgetScriptElement = document.createElement('script');
    this.packetaWidgetScriptElement.src =
      'https://widget.packeta.com/v6/www/js/library-1.0.7.js';
    document.body.appendChild(this.packetaWidgetScriptElement);

    this.deliverySettings = this.store
      .select((state) => state.eshopOrderModule.draft?.delivery)
      .pipe(
        filter((state) => state !== undefined)
      ) as Observable<DeliveryDetails>;

    const firstFreeImagesSelector = createSelector(
      (deliverySettings: DeliveryDetails | undefined) =>
        (deliverySettings?.payload as PacketaPickupDetails)?.photos ?? [],
      (data) =>
        data
          .slice(0, Math.min(3, data.length))
          .map((e) => e.normal ?? e.thumbnail)
    );

    this.initialized = this.store
      .select((state) => state.eshopOrderModule.draft?.delivery)
      .pipe(
        switchMap((state) => {
          return of(
            state !== undefined && state.provider.id == this.deliveryProvider.id
          );
        })
      );

    this.firstThreeImages = this.deliverySettings.pipe(
      select(firstFreeImagesSelector)
    );
  }

  openPacketaWidget() {
    Packeta.Widget.pick(
      environment.packeta.apikey,
      this.packetaPickCallback.bind(this),
      {
        country: 'sk',
        language: 'sk',
        valueFormat:
          '"Packeta",id,carrierId,carrierPickupPointId,name,city,street',
        view: 'modal',
      }
    );
  }

  packetaPickCallback(point: PacketaPickupDetails) {
    this.store.dispatch(
      OrderActions.configureShippingDetails({
        cost: this.getShippingPrice(point),
        payload: point,
      })
    );
  }
}
