<div class="container">
  <div class="row">
    <div class="col-md-6">

      <div class="d-block d-md-none">
        <h2 class="card-tittle">{{ (product | async)!.name }}</h2>

        <div class="row fs-4">
          <div class="col-5">
            <div class="row">
              <div class="col-5 col-form-label" i18n>Price:</div>
              <div class="col-3 col-form-label">
                {{ (product | async)!.calculatedPrice! }}€
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-auto paintit-preview-img pt-0 pt-md-3 justify-content-center"
      >
        <div class="d-flex justify-content-center">
          <div
            *ngIf="(conceptSelected | async) && !(conceptsInitialized | async)"
          >
            <svg
              class="bd-placeholder-img card-img-top"
              width="100%"
              height="250px"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#e3e3e3"></rect>
            </svg>
          </div>
          <img-comparison-slider
            [value]="dividerState"
            *ngIf="(conceptSelected | async) && (conceptsInitialized | async)"
          >
            <img
              slot="first"
              [src]="(concept | async).source_thumbnail"
              class="img-fluid"
            />
            <img
              slot="second"
              [src]="
                (concept_result | async)?.rendered_thumbnail ??
                (concept | async).source_thumbnail
              "
              class="img-fluid"
            />
          </img-comparison-slider>
        </div>

        <div
          class="container"
          *ngIf="(conceptSelected | async) && (conceptsInitialized | async)"
        >
          <div
            class="d-flex justify-content-center pt-2"
            *ngIf="!!(concept_result | async)"
          >
            <fa-icon [icon]="faArrowLeft"></fa-icon>
            <span class="mx-3" i18n> Drag to compare </span>
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </div>

          <div
            class="d-flex justify-content-center pt-2"
            *ngIf="!(concept_result | async)"
          >
            <p i18n>Please choose a size to reveal the preview</p>
          </div>
        </div>

        <div
          class="container"
          *ngIf="(conceptSelected | async) && !(conceptsInitialized | async)"
        >
          <p class="placeholder-glow text-center">
            <span class="placeholder col-6"></span>
          </p>
        </div>

        <div *ngIf="!(conceptSelected | async)" class="container text-center">
          <div class="row">
            <fa-icon
              [icon]="faImage"
              size="10x"
              [fixedWidth]="true"
              class="mt-4 mt-md-5 pt-md-5"
            ></fa-icon>
          </div>
        </div>

        <div class="container">
          <div class="row justify-content-around pt-1 pt-md-4">
            <button
              *ngIf="
                !(conceptSelected | async) && (hasCompatibleConcepts | async)
              "
              class="btn btn-secondary btn-sm col-4"
              (click)="chooseFromGallery()"
              i18n
            >
              Choose from gallery
            </button>
            <button
              *ngIf="(conceptSelected | async) && (conceptsInitialized | async)"
              class="btn btn-secondary btn-sm col-4"
              (click)="chooseFromGallery()"
              i18n
            >
              Change Image
            </button>
            <button
              *ngIf="conceptsInitialized | async"
              class="btn btn-secondary btn-sm col-4"
              (click)="createNew()"
              i18n
            >
              Create new
            </button>

            <p
              class="placeholder-glow col-4"
              *ngIf="
                (conceptSelected | async) && !(conceptsInitialized | async)
              "
            >
              <span class="placeholder placeholder-lg col-12 bg-warning"></span>
            </p>

            <p
              class="placeholder-glow col-4"
              *ngIf="
                (conceptSelected | async) && !(conceptsInitialized | async)
              "
            >
              <span class="placeholder placeholder-lg col-12 bg-warning"></span>
            </p>
          </div>
        </div>
      </div>

      <div class="container" *ngIf="!(conceptSelected | async)">
        <p class="text-primary py-5 text-center" i18n>
          Please select a concept from your gallery or create a new one.
        </p>
      </div>
    </div>

    <div class="col-md-6 mt-2 mt-md-5 pt-2 mt-md-0 pt-md-0">
      <div class="container-fluid">
        <div class="d-none d-md-block">
          <h2 class="card-tittle">{{ (product | async)!.name }}</h2>

          <div class="row fs-5">
            <div class="col-5 col-lg-6">
              <div class="row">
                <div class="col-5 col-lg-3 col-form-label" i18n>Price:</div>
                <div class="col-3 col-form-label">
                  {{ (product | async)!.calculatedPrice! }}€
                </div>
              </div>
            </div>
          </div>

          <hr class="border-bottom border-1 border-dark" />
        </div>

        <div class="row d-md-block d-none">
          <p>
            {{
              (product | async)!.description
                | xlocalize : (product | async)!.descriptionLocale
            }}
          </p>
        </div>

        <div class="container d-sm-block d-md-none mb-2" ngbAccordion>
          <div ngbAccordionItem [collapsed]="descriptionAccordionCollapsed">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton i18n>Product Description</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  {{
                    (product | async)!.description
                      | xlocalize : (product | async)!.descriptionLocale
                  }}
                </ng-template>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row pt-3"
          *ngIf="(conceptSelected | async) && (conceptsInitialized | async)"
        >
          <dt class="col-6 col-lg-4" i18n>Format</dt>
          <dd class="col-6 col-lg-8">
            {{ (concept | async).concept_format.name }}
          </dd>
        </div>

        <div class="row" *ngIf="!!(concept_result | async)">
          <dt class="col-6 col-lg-4" i18n>Facet count</dt>
          <dd class="col-6 col-lg-8">
            {{ (concept_result | async).facetCount ?? 0 }}
          </dd>

          <dt class="col-6 col-lg-4" i18n>Color count</dt>
          <dd class="col-6 col-lg-8">
            {{ (concept_result | async).color_palette?.length ?? 0 }}
          </dd>
        </div>

        <ng-container
          *ngIf="(conceptSelected | async) && (conceptsInitialized | async)"
        >
          <ng-container *ngIf="!(allConceptsRendered | async)">
            <p i18n>
              Your chosen concept is being prepared. Please wait for a few
              seconds
            </p>

            <div
              class="row"
              *ngFor="let cr of (concept | async).concept_results; index as i"
            >
              <dt class="col-3" i18n>Concept {{ i + 1 }}</dt>
              <dd class="col-9">
                <ngb-progressbar
                  *ngIf="cr.state == 'rendering'"
                  type="warning"
                  textType="white"
                  [value]="cr.progress!"
                  [showValue]="true"
                />
                <span *ngIf="cr.state == 'created'" i18n> Waiting </span>
                <span *ngIf="cr.state == 'rendered'" i18n> Rendered </span>
              </dd>
            </div>
          </ng-container>

          <ng-container *ngIf="allConceptsRendered | async">
            <h5 class="row fs-6 p-3 fw-bold" i18n>Select your options:</h5>
            <div *ngFor="let formItem of formSettings | async">
              <div [ngSwitch]="formItem.type">
                <div *ngSwitchCase="'selection'" class="row">
                  <app-selection-product-option
                    [formItem]="formItem"
                    [productId]="(product | async).id"
                    (selection)="optionSelected($event)"
                  ></app-selection-product-option>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <app-add-to-cart
          *ngIf="conceptsInitialized | async"
          class="row"
          [product]="product | async"
          [disabled]="(productSelectionsInvalid | async)!"
          [count]="(product | async)!.count"
        ></app-add-to-cart>

        <div class="row" *ngIf="!(conceptsInitialized | async)">
          <p class="placeholder-glow text-center">
            <span class="placeholder placeholder-lg bg-warning col-12"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
