import { createActionGroup, props } from '@ngrx/store';
import { Concept, ConceptFormat, ConceptResult, ThumbnailResultDto } from './concept.types';

export const ConceptAction = createActionGroup({
  source: 'concept',
  events: {
    createConcept: props<{ concept: Concept }>(),
    updateConcept: props<{ concept: Concept }>(),
    deleteConcept: props<{ id: number }>(),
    refreshConcepts: props<{ concepts: Concept[] }>(),
    updateConceptResult: props<{
      conceptId: number;
      concept_result: ConceptResult;
    }>(),
    updateConceptResultProgress: props<{
      conceptId: number;
      concept_result_id: number;
      progress: number;
    }>(),
    selectConceptResult: props<{
      concept_result: ConceptResult;
    }>(),
    selectConcept: props<{ concept: Concept }>(),
    updateThumbnail: props<ThumbnailResultDto>(),
    listFormats: props<{ formats: ConceptFormat[] }>(),
  },
});
