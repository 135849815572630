import { createReducer, on } from '@ngrx/store';
import { AuthAction } from './auth.action';

export interface AnonSessionStore {
  cartActivated?: boolean;
  cartUsed?: boolean;
  conceptCreated?: boolean;
  addressAdded?: boolean;
  orderCreated?: boolean;
}

export interface AnonMigrationState {
  cartMigrated?: boolean;
  conceptMigrated?: boolean;
  addressMigrated?: boolean;
  orderMigrated?: boolean;
}

export interface authState {
  permissions: string[];
  authLoaded: boolean;
  anonState: AnonSessionStore;
  anonMigration: AnonMigrationState;
  anonMigrating: boolean;
  anonMigrated: boolean;
  anonStateLoaded: boolean;
}

export const initialState: authState = {
  permissions: [],
  authLoaded: false,
  anonState: {},
  anonMigration: {},
  anonMigrating: false,
  anonMigrated: false,
  anonStateLoaded: false,
};

export const authReducer = createReducer(
  initialState,
  on(AuthAction.permissionsFetched, (state, { permissions }) => {
    return {
      ...state,
      permissions,
      authLoaded: true,
    };
  }),
  on(AuthAction.anonStateFetched, (state, { anonState }) => {
    return {
      ...state,
      anonState,
      anonStateLoaded: true,
    };
  }),
  on(AuthAction.anonMigrationRequest, (state, { anonState }) => {
    return {
      ...state,
      anonMigrating: true,
      anonMigrated: false,
      anonMigration: {
        cartMigrated: false,
        conceptMigrated: false,
        addressMigrated: false,
        orderMigrated: false,
      },
    };
  }),
  on(AuthAction.anonCartMigrated, (state, { cart }) => {
    return {
      ...state,
      anonMigration: {
        ...state.anonMigration,
        cartMigrated: true,
      },
    };
  }),
  on(AuthAction.anonConceptMigrated, (state, { concepts }) => {
    return {
      ...state,
      anonMigration: {
        ...state.anonMigration,
        conceptMigrated: true,
      },
    };
  }),
  on(AuthAction.anonAddressMigrated, (state, { addresses }) => {
    return {
      ...state,
      anonMigration: {
        ...state.anonMigration,
        addressMigrated: true,
      },
    };
  }),
  on(AuthAction.anonOrderMigrated, (state, { orders }) => {
    return {
      ...state,
      anonMigration: {
        ...state.anonMigration,
        orderMigrated: true,
      },
    };
  }),
  on(AuthAction.anonMigrated, (appState, { state }) => {
    return {
      ...appState,
      anonMigrating: !state,
      anonMigrated: state,
      anonState: state ? {} : { ...appState.anonState },
    };
  })
);
