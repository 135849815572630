<div class="position-fixed bottom-0 start-50 translate-middle-x">
  <ngb-alert
    #selfClosing
    class="alert-wider"
    *ngFor="let alert of alerts | async"
    [type]="alert.type"
    (closed)="close(alert)"
    ><span *ngIf="!alert.html">
      {{ alert.message }}
    </span>
    <span *ngIf="alert.html" [innerHTML]="alert.message"> </span>
  </ngb-alert>
</div>
