import { createReducer, on } from '@ngrx/store';
import {
  Country,
  DeliveryProvider,
  Order,
  OrderAddress,
  PaymentProvider,
} from './order.types';
import { OrderActions } from './order.action';

export interface eshopOrdersModuleState {
  orders: Order[];
  ordersInitialized: boolean;
  addresses: OrderAddress[];
  addressesInitialized: boolean;
  draft: Partial<Order> | null;
  countries: Country[];
  countriesInitialized: boolean;
  deliveryProviders: { [key: number]: DeliveryProvider };
  deliveryProvidersInitialized: boolean;
  paymentProviders: { [key: number]: PaymentProvider };
  paymentProvidersInitialized: boolean;
}

export const initialState: eshopOrdersModuleState = {
  orders: [],
  ordersInitialized: false,
  addresses: [],
  addressesInitialized: false,
  draft: null,
  countries: [],
  countriesInitialized: false,
  deliveryProviders: {},
  deliveryProvidersInitialized: false,
  paymentProviders: {},
  paymentProvidersInitialized: false,
};

export const eshopOrderReducer = createReducer(
  initialState,
  //orders
  on(OrderActions.refreshOrders, (state, { orders }) => {
    return {
      ...state,
      ordersInitialized: true,
      orders,
    };
  }),
  on(OrderActions.createOrder, (state, { order }) => {
    return {
      ...state,
      orders: [order, ...state.orders],
    };
  }),
  //draft
  on(OrderActions.createDraft, (state, { cart }) => {
    return {
      ...state,
      draft:
        cart == null
          ? cart
          : {
              locale: $localize.locale ?? 'en',
              order_items: cart.cart_items.map((e) => {
                return {
                  count: e.count,
                  item_options: e.cart_item_options.map((e) => {
                    return {
                      key: e.product_option.key,
                      value: e.valueOverride!,
                      isUserInput: e.product_option.isUserInput,
                      type: e.product_option.type,
                      meta: e.product_option.meta,
                    };
                  }),
                  product: e.product,
                  priceSnapshot:
                    e.cart_item_options.reduce((prev, cur) => {
                      return prev + (cur.product_option.priceModifier ?? 0);
                    }, e.product.price) * e.count,
                };
              }),
            },
    };
  }),
  on(OrderActions.selectBillingAddress, (state, { address }) => {
    return {
      ...state,
      draft: {
        ...state.draft,
        billing_address: address,
      },
    };
  }),
  on(OrderActions.selectDeliveryProvider, (state, { delivery_provider }) => {
    return {
      ...state,
      draft: {
        ...state.draft,
        delivery: {
          ...state.draft?.delivery,
          provider: delivery_provider,
          cost: 0,
          payload: undefined,
          configured: false,
        },
      },
    };
  }),
  on(OrderActions.selectPaymentProvider, (state, { payment_provider }) => {
    return {
      ...state,
      draft: {
        ...state.draft,
        payment: {
          ...state.draft?.payment,
          provider: payment_provider,
          cost: 0,
          payload: undefined,
          configured: false,
        },
      },
    };
  }),
  //addresses
  on(OrderActions.listAddresses, (state, { addresses }) => {
    return {
      ...state,
      addresses,
      addressesInitialized: true,
    };
  }),
  on(OrderActions.addAddress, (state, { address }) => {
    return {
      ...state,
      addresses: [...state.addresses, address],
    };
  }),
  //addresses - countries
  on(OrderActions.listCountries, (state, { countries }) => {
    return {
      ...state,
      countries,
      countriesInitialized: true,
    };
  }),
  //fulfillment - delivery providers
  on(OrderActions.listDeliveryProviders, (state, { deliveryProviders }) => {
    return {
      ...state,
      deliveryProviders: deliveryProviders.reduce((prev, cur) => {
        return { ...prev, [cur.id]: cur };
      }, {}),
      deliveryProvidersInitialized: true,
    };
  }),
  on(OrderActions.configureShippingDetails, (state, { cost, payload }) => {
    return {
      ...state,
      draft: {
        ...state.draft,
        delivery: {
          ...state.draft?.delivery!,
          cost,
          payload,
          configured: true,
        },
      },
    };
  }),
  //fulFillment - payment providers
  on(OrderActions.listPaymentProviders, (state, { paymentProviders }) => {
    return {
      ...state,
      paymentProviders: paymentProviders.reduce((prev, cur) => {
        return { ...prev, [cur.id]: cur };
      }, {}),
      paymentProvidersInitialized: true,
    };
  }),
  on(OrderActions.configurePaymentDetails, (state, { cost, payload }) => {
    return {
      ...state,
      draft: {
        ...state.draft,
        payment: {
          ...state.draft?.payment!,
          cost,
          payload,
          configured: true,
        },
      },
    };
  }),
  //billing info
  on(OrderActions.requestBillingInformation, (state, { orderId }) => {
    return {
      ...state,
      orders: state.orders.map((e) =>
        e.id == orderId
          ? {
              ...e,
              billing: {
                loading: true,
                error: false,
                details: null,
              },
            }
          : e
      ),
    };
  }),
  on(
    OrderActions.loadBillingInformation,
    (state, { orderId, details, error }) => {
      return {
        ...state,
        orders: state.orders.map((e) =>
          e.id == orderId
            ? {
                ...e,
                billing: {
                  loading: false,
                  details: error ? null : details,
                  error,
                },
              }
            : e
        ),
      };
    }
  )
);
