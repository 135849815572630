import { Component, OnInit } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { appStore } from '../../../app.store';
import { Product } from '../store/product.types';
import {
  Observable,
  Subscription,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { eshopProductModuleState } from '../store/product.reducer';
import { ProductsService } from '../products.service';
import { ProductAction } from '../store/products.action';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ProductGalleryCarouselComponent } from '../product-gallery-carousel/product-gallery-carousel.component';
import { ProductPlaceholderComponent } from '../product-placeholder/product-placeholder.component';

@Component({
  selector: 'app-product-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    StoreModule,
    FontAwesomeModule,
    ProductGalleryCarouselComponent,
    ProductPlaceholderComponent,
  ],
  templateUrl: './product-list.component.html',
  styleUrl: './product-list.component.scss',
})
export class ProductListComponent implements OnInit {
  products: Observable<Product[]>;
  productsInitialized: Observable<boolean>;

  faSpinner = faSpinner;

  constructor(
    private store: Store<appStore>,
    private productService: ProductsService
  ) {}

  attributeArraySelector(object: any[], attribute: string) {
    return object.map((e) => e[attribute]);
  }

  ngOnInit(): void {
    this.products = this.store
      .select((state) => state.eshopProductModule)
      .pipe(
        filter((module) => module.initialized),
        map((module) => module.products)
      );

    this.productsInitialized = this.store.select(
      (state) => state.eshopProductModule.initialized
    );

    this.store
      .select((state: appStore) => state.eshopProductModule)
      .pipe(
        filter((state) => !state.initialized),
        switchMap((state) => this.productService.listProducts()),
        tap((products) =>
          this.store.dispatch(ProductAction.refreshProducts({ products }))
        )
      )
      .subscribe();
  }
}
