import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Color } from '../store/concept.types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-color-palette',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './color-palette.component.html',
  styleUrl: './color-palette.component.scss',
})
export class ColorPaletteComponent {
  activeModal = inject(NgbActiveModal);

  @Input() colorPalette: Color[];

  getRgbCSSElement(color: Color) {
    return `background-color: rgb(${color.r}, ${color.g}, ${color.b})`;
  }
}
