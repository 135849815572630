import { environment } from '../../../environments/environment';

export const getBackendUrl = (url: string) => {
  const lang = $localize.locale;
  if (lang) {
    const repl = environment.backendUrlReplacements.find((e) => e.lang == lang);
    if (repl) {
      return url.replace(repl.from, repl.to);
    } else {
      return url;
    }
  } else {
    return url;
  }
};
