import { Component, OnInit } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { appStore } from '../../../app.store';
import { filter, map, Observable, of, tap } from 'rxjs';
import { Concept } from '../../store/concept.types';
import { AdminConceptsService } from '../admin-concept.service';
import { ConceptAdminAction } from '../store/concept-admin.action';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConceptComponent } from '../../concept/concept.component';

@Component({
  selector: 'app-admin-concept-list',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxPaginationModule,
    StoreModule,
    ConceptComponent,
  ],
  templateUrl: './admin-concept-list.component.html',
  styleUrl: './admin-concept-list.component.scss',
})
export class AdminConceptListComponent implements OnInit {
  constructor(
    private store: Store<appStore>,
    private adminConceptService: AdminConceptsService
  ) {}

  faSpinner = faSpinner;
  concepts$: Observable<Concept[]> = of([]);
  currentPage: number = 1;

  ngOnInit(): void {
    this.concepts$ = this.store
      .select((state) => state.adminConcept)
      .pipe(
        tap((state) => {
          if (!state.initialized) {
            this.adminConceptService.getAllConcepts().subscribe((concepts) => {
              this.store.dispatch(
                ConceptAdminAction.refreshConcepts({ concepts })
              );
            });
          }
        }),
        filter((state) => state.initialized),
        map((state) => state.concepts)
      );
  }

  pageChange($event: any) {
    this.currentPage = $event;
  }
}
