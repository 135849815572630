export interface ProductOptionsFormSetting {
  key: string;
  type: 'radio' | 'selection' | 'dropdown' | 'text' | 'hidden';
  options: { value: string; selected: boolean }[];
}

export enum UserInputTypes {
  RADIO = 'radio', // Will build radio button for product details
  DROPDOWN = 'dropdown', // will build dropdown menu for product details
  INPUT = 'input', // will build user input for product details
  SELECTION = 'selection', // will build clickable selection inline for product details
}
