import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { appStore } from '../../app.store';
import { Store } from '@ngrx/store';
import { filter, map, Observable, Subscription, take, tap } from 'rxjs';
import {
  AnonMigrationState,
  AnonSessionStore,
} from '../../auth/store/auth.reducer';
import { CommonModule } from '@angular/common';
import { AuthAction } from '../../auth/store/auth.action';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-anon-migration',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './anon-migration.component.html',
  styleUrl: './anon-migration.component.scss',
})
export class AnonMigrationComponent implements OnInit, OnDestroy {
  constructor(private store: Store<appStore>) {}

  faSpinner = faSpinner;
  faCheck = faCheck;

  ngOnDestroy(): void {
    this.anonMigratedSub.unsubscribe();
  }

  anonContent: Observable<AnonSessionStore>;
  anonMigratedSub: Subscription;

  migrating: Observable<boolean>;
  migrationState: Observable<AnonMigrationState>;
  migrated = false;

  ngOnInit(): void {
    this.anonContent = this.store
      .select((state) => state.auth)
      .pipe(
        filter((state) => state.anonStateLoaded),
        take(1),
        map((data) => data.anonState)
      );

    this.anonMigratedSub = this.store
      .select((state) => state.auth)
      .pipe(
        filter((state) => state.anonStateLoaded),
        map((data) => data.anonMigrated)
      )
      .subscribe((migrated) => {
        console.log('migtated state', migrated);
        if (migrated) {
          this.migrated = true;
          setTimeout(() => {
            this.activeModal.close();
          }, 2000);
        }
      });

    this.migrating = this.store
      .select((state) => state.auth)
      .pipe(
        filter((state) => state.anonStateLoaded),
        map((data) => data.anonMigrating)
      );

    this.migrationState = this.store
      .select((state) => state.auth)
      .pipe(
        filter((state) => state.anonStateLoaded),
        map((data) => data.anonMigration)
      );
  }

  activeModal = inject(NgbActiveModal);

  migrate() {
    this.anonContent
      .pipe(
        take(1),
        tap((e) => {
          this.store.dispatch(
            AuthAction.anonMigrationRequest({
              anonState: e,
            })
          );
        })
      )
      .subscribe();
  }
}
