<section class="py-4 text-center container">
  <div class="row py-lg-5">
    <div class="col-lg-8 col-md-10 mx-auto">
      <h1 class="fw-light" i18n="Products Title" i18n>Products</h1>
      <p class="lead text-body-secondary" i18n>
        Choose from our Numero Art Product Line. We have several sizes
        available, which will fit everyone's need. Each product comes with a
        personalized printed canvas, high quality paint pods and a set of
        brushes.
      </p>
    </div>
  </div>
</section>

<div
  class="page-layout"
  *ngIf="!(productsInitialized | async); else pageInitialized"
>
  <section class="h-100">
    <div class="container py-5">
      <div class="row">
        <app-product-placeholder
          class="col-12 col-md-4 mb-4 mb-lg-0"
          *ngFor="let fakeProduct of [].constructor(3)"
        ></app-product-placeholder>
      </div>
    </div>
  </section>
</div>
<ng-template #pageInitialized>
  <section class="h-100">
    <div class="container py-5">
      <div class="row">
        <div
          class="col-12 col-md-4 mb-4 mb-lg-0"
          *ngFor="let product of products | async"
        >
          <div class="card text-black">
            <app-product-gallery-carousel
              [images]="attributeArraySelector(product.product_images, 'url')"
            ></app-product-gallery-carousel>
            <div class="card-body">
              <div class="text-center mt-1">
                <h4 class="card-title">{{ product.name }}</h4>
                <h6 class="text-primary mb-1 pb-3" i18n>
                  Starting at {{ product.price }}€
                </h6>
              </div>

              <div class="text-center">
                <div class="p-3 mx-n3 mb-4" style="background-color: #eff1f2">
                  <h5 class="mb-0" i18n>Sizes</h5>
                </div>
                <div class="d-flex flex-column mb-4">
                  <ng-container *ngFor="let op of product.product_options">
                    <span *ngIf="op.key == 'size'">{{ op.value }}</span>
                  </ng-container>
                </div>
              </div>

              <div class="d-flex flex-row">
                <button
                  type="button"
                  class="btn btn-secondary flex-fill mx-1"
                  [routerLink]="['/products/', product.id]"
                  i18n
                >
                  Buy now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
