import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Product } from '../store/product.types';
import {
  Observable,
  filter,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Store, StoreModule } from '@ngrx/store';
import { appStore } from '../../../app.store';
import { CommonModule } from '@angular/common';
import { PaintByNumbersComponent } from './paint-by-numbers/paint-by-numbers.component';
import { ProductsService } from '../products.service';
import { ProductAction } from '../store/products.action';

@Component({
  selector: 'app-product-detail',
  standalone: true,
  imports: [RouterModule, StoreModule, CommonModule, PaintByNumbersComponent],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss',
})
export class ProductDetailComponent implements OnInit {
  product: Observable<Product>;
  initialized: Observable<boolean>;

  // View Types
  PAINT_BY_NUMBERS = 'PAINT_BY_NUMBERS';

  constructor(
    private route: ActivatedRoute,
    private store: Store<appStore>,
    private productService: ProductsService
  ) {}

  ngOnInit(): void {
    this.initialized = this.store.select(
      (state) => state.eshopProductModule.initialized
    );

    this.product = this.store
      .select((state) => state.eshopProductModule)
      .pipe(
        filter((state) => state.initialized),
        switchMap((state) =>
          of(
            state.products.find(
              (e) =>
                e.id == this.route.snapshot.params['id'] ||
                e.slug == this.route.snapshot.params['id']
            ) as Product
          )
        )
      );


    this.store
      .select((store) => store.eshopProductModule)
      .pipe(
        take(1),
        tap((state) => {
          if (!state.initialized) {
            this.productService.listProducts().subscribe((products) => {
              this.store.dispatch(ProductAction.refreshProducts({ products }));
            });
          }
        })
      )
      .subscribe();
  }
}
