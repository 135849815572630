import { createReducer, on } from '@ngrx/store';
import { Cart } from './cart.types';
import { CartAction } from './cart.actions';

export interface eshopCartModuleState {
  cart: Cart | null;
  initialized: boolean;
}

export const initialState: eshopCartModuleState = {
  cart: null,
  initialized: false,
};

export const eshopCartReducer = createReducer(
  initialState,
  on(CartAction.refreshCart, (state, { cart }) => {
    return {
      ...state,
      cart: {
        ...cart,
        cart_items: cart.cart_items.map((item) => {
          return {
            ...item,
            unitPrice: item.cart_item_options.reduce((prev, cur) => {
              return prev + cur.product_option.priceModifier!;
            }, item.product.price),
            itemPrice:
              item.cart_item_options.reduce((prev, cur) => {
                return prev + cur.product_option.priceModifier!;
              }, item.product.price) * item.count,
          };
        }),
        totalPrice: cart.cart_items.reduce((prev, item) => {
          const basePrice = item.product.price;
          const itemPrice = item.cart_item_options.reduce((prev, cur) => {
            return prev + cur.product_option.priceModifier!;
          }, basePrice);
          return prev + itemPrice * item.count;
        }, 0),
      },
      initialized: true,
    };
  })
);
