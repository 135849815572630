import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderAddress } from '../../store/order.types';
import { CommonModule } from '@angular/common';
import { LocalizerPipe } from '../../../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-address-card',
  standalone: true,
  imports: [CommonModule, LocalizerPipe],
  templateUrl: './address-card.component.html',
  styleUrl: './address-card.component.scss',
})
export class AddressCardComponent {

  selectedStr = $localize`Selected`;
  selectStr = $localize`Select`;

  @Input()
  address: OrderAddress;

  @Input()
  selected: boolean;

  @Input()
  isSelectible: boolean = true;

  @Output()
  select = new EventEmitter();

  selectBillingAddress() {
    this.select.emit(this.address);
  }
}
