<div class="card text-black">
  <svg
    class="bd-placeholder-img card-img-top"
    width="100%"
    height="180"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Placeholder"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
  >
    <title>Placeholder</title>
    <rect width="100%" height="100%" fill="#e3e3e3"></rect>
  </svg>

  <div class="card-body">
    <div class="text-center mt-1 placeholder-glow">
      <p class="placeholder placeholder-lg col-12" style="width: 25%"></p>
      <br />
      <p class="placeholder bg-secondary col-12" style="width: 25%"></p>
    </div>

    <div class="text-center placeholder-glow">
      <div class="p-3 col-12 placeholder placeholder-lg"></div>
    </div>
    <br />

    <div class="d-flex flex-row placeholder-glow">
      <button
        type="button"
        class="btn btn-secondary disabled placeholder flex-fill mx-1"
      ></button>
    </div>
  </div>
</div>
