import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Order } from '../store/order.types';
import { environment } from '../../../../environments/environment';
import { getBackendUrl } from '../../../common/helper/backend.helper';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private httpClient: HttpClient) {}

  createOrder(order: Partial<Order>) {
    return this.httpClient.post<Order>(
      `${getBackendUrl(environment.backendUrl)}/order`,
      order,
      { withCredentials: true }
    );
  }

  listOrders() {
    return this.httpClient.get<Order[]>(
      `${getBackendUrl(environment.backendUrl)}/order`,
      {
        withCredentials: true,
      }
    );
  }

  migrateOrders() {
    return this.httpClient.post<Order[]>(
      `${getBackendUrl(environment.backendUrl)}/order/migrate`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
