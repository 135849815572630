import { Component, Input } from '@angular/core';
import { PacketaPickupDetails } from '../../../order-create/delivery-method/packeta-pickup/packeta-pickup.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-packeta-pickup',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="row">
      <p>
        {{ payload.place }}
      </p>
      <p>
        {{ payload.name }}
      </p>
      <div>
        <div class="row">
          <div
            *ngFor="let photo of firstThreeImages()"
            class="col-xs-12 col-sm-4 col-md-4 p-3"
          >
            <img [src]="photo" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrl: './packeta-pickup.component.scss',
})
export class PacketaPickupComponent {
  @Input() payload: PacketaPickupDetails;

  firstThreeImages() {
    return this.payload.photos
      .slice(0, Math.min(3, this.payload.photos.length))
      .map((e) => e.normal ?? e.thumbnail);
  }
}
