<div
  class="page-layout"
  *ngIf="!(cart | async)!.initialized; else cartInitialized"
>
  <div class="container">
    <div class="d-flex align-items-center justify-content-center w-100 p-5">
      <p class="h3" i18n>
        <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
        Loading Page...
      </p>
    </div>
  </div>
</div>
<ng-template #cartInitialized>
  <section class="h-100">
    <div class="container h-100 py-5">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-10">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <h3 class="fw-normal mb-0 text-black" i18n="Shopping cart title">Shopping Cart</h3>
          </div>

          <div
            class="card rounded-3 mb-4"
            *ngFor="let cart_item of (cart | async)!.cart!.cart_items"
          >
            <div class="card-body p-4">
              <div
                class="row d-flex justify-content-between align-items-center"
              >
                <!-- Image -->
                <app-cart-paint-by-numbers
                  [cartItem]="cart_item"
                  class="col-6 col-lg-2 col-xl-2 p-sm-2 p-lg-0"
                  *ngIf="cart_item.product.type == 'PAINT_BY_NUMBERS'"
                ></app-cart-paint-by-numbers>

                <!-- Details -->
                <div class="col-6 col-lg-3 col-xl-3">
                  <p class="lead fw-normal mb-2">
                    {{ cart_item.product.name }}
                  </p>
                  <!-- Options Renderer -->
                  <app-item-details [cartItem]="cart_item"></app-item-details>
                </div>

                <!-- Count + price -->
                <div class="col-3 col-sm-5 col-md-5 col-lg-3 col-xl-2">
                  {{ cart_item.unitPrice }}€ x {{ cart_item.count }}
                </div>

                <!-- Item Price -->
                <div
                  class="col-3 col-sm-5 col-md-5 col-lg-2 col-xl-2 offset-lg-1"
                >
                  <!-- Item Price component-->
                  <h5 class="mb-0">{{ cart_item.itemPrice }}€</h5>
                </div>

                <!-- Delete -->
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 text-end">
                  <!-- Icon delete -->
                  <a class="text-danger" (click)="deleteCartItem(cart_item.id)">
                    <fa-icon [icon]="faTrashCan"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="flex-row d-flex justify-content-between">
                <div class="p-2">
                  <button
                    type="button"
                    class="btn btn-secondary btn-block"
                    (click)="createOrderDraft()"
                    i18n="Proceed to order from cart"
                  >
                    Proceed to Order
                  </button>
                </div>
                <div class="p-3">
                  <span class="text-end fs-5"
                    ><span class="fw-bold" i18n="Total Price in Cart">Total: </span
                    >{{ (cart | async)!.cart!.totalPrice }}€</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
