<div class="modal-header">
  <h4 class="modal-title" i18n>Colour Palette</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <table class="table table-image align-middle">
    <thead>
      <th scope="col" class="text-center" i18n>Color ID</th>
      <th scope="col" colspan="2" class="text-center" i18n>Color</th>
      <th scope="col" class="text-center" i18n>Area covered</th>
    </thead>
    <tbody>
      <tr scope="row" *ngFor="let color of colorPalette">
        <th scope="row" class="text-center">{{ color.colorId }}</th>
        <td class="w-25" [style]="getRgbCSSElement(color)">
          &nbsp;
        </td>
        <td class="w-25">RGB: [{{ color.r }}, {{ color.g }}, {{ color.b }}]</td>
        <td class="text-center">
          <p>{{ color.percentage }}%</p>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="activeModal.close('Close click')"
    i18n="close color palette modal"
  >
    Close
  </button>
</div>
