import { Component, Input, OnInit } from '@angular/core';
import { DeliveryProvider } from '../../store/order.types';
import { PacketaPickupComponent } from '../delivery-method/packeta-pickup/packeta-pickup.component';
import { CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { appStore } from '../../../../app.store';
import { Store } from '@ngrx/store';
import { OrderActions } from '../../store/order.action';
import { PacketaAddressComponent } from '../delivery-method/packeta-address/packeta-address.component';
import { LocalizerPipe } from '../../../../common/pipes/countryLocalePipe';

@Component({
  selector: 'app-delivery-provider-accordion-item',
  standalone: true,
  imports: [
    CommonModule,
    PacketaPickupComponent,
    PacketaAddressComponent,
    NgbAccordionModule,
    LocalizerPipe
  ],
  templateUrl: './delivery-provider-accordion-item.component.html',
  styleUrl: './delivery-provider-accordion-item.component.scss',
})
export class DeliveryProviderAccordionItemComponent implements OnInit {
  constructor(private store: Store<appStore>) {}

  ngOnInit(): void {}

  @Input()
  deliveryProvider: DeliveryProvider;

  @Input()
  selectedProvider: DeliveryProvider | undefined;

  selectDeliveryProvider() {
    this.store.dispatch(
      OrderActions.selectDeliveryProvider({
        delivery_provider: this.deliveryProvider,
      })
    );
  }
}
