<div class="row" *ngIf="!(canCreateConcept | async)">
  <div class="p-5 mb-4 bg-body-tertiary rounded-3">
    <div class="container-fluid py-5">
      <h2 class="display-5 fw-bold" i18n>Can't create more concepts</h2>
      <p class="col-12 col-md-10 fs-4" i18n>
        To preserve resources, this portal is limited to a single concept for
        unauthanticated users. Please click the button below to sign-up/login to
        create a new concept
      </p>
      <button class="btn btn-secondary" type="button" (click)="auth.loginWithRedirect()" i18n>
        Log in
      </button>
    </div>
  </div>
</div>

<div class="row justify-content-center py-5" *ngIf="canCreateConcept | async">
  <div class="col-12 col-md-10 col-lg-8 text-center py-5">
    <form [formGroup]="createConceptForm" (ngSubmit)="onSubmit()">
      <input type="file" (change)="onFileChange($event)" class="form-control d-none" id="formFile" #fileInput />

      <div class="col-12" *ngIf="!fileLoaded">
        <main class="px-3">
          <h1 i18n>Create a new concept.</h1>
          <p class="lead" i18n>
            Create a new concept by uploading your picture. Capture your
            greatest moment on canvas with a simple click and unleash your inner
            artist
          </p>

          <p class="lead py-5">
            <fa-icon [icon]="faUpload" size="6x"></fa-icon>
          </p>

          <div class="pb-2 text-danger" *ngIf="createConceptForm.controls['file'].errors?.['incorrectFileType']" i18n>
            Incorrect file type. JPG, JPEG or PNG are allowed.
          </div>

          <p class="lead">
            <a (click)="selectFile()" class="btn btn-secondary btn-lg btn-block" i18n>Select File</a>
          </p>
        </main>
      </div>

      <div class="col-12" *ngIf="fileLoaded">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
          format="png" (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()"
          (cropperReady)="cropperLoaded = true" [resizeToWidth]="1058"></image-cropper>

        <div class="row text-start p-3">
          <h4 i18n>Select aspect ratio:</h4>
        </div>

        <div class="row justify-content-around">
          <div class="form-check col-12 col-sm-6 col-md-3" *ngFor="let or of formats | async">
            <label [class]="[
                'btn',
                createConceptForm.get('aspectRatio').value == or.name
                  ? 'btn-secondary'
                  : 'btn-outline-secondary',
                'w-100'
              ]">
              <input type="radio" class="btn-check" formControlName="aspectRatio" [value]="or.name" />{{ or.name |
              xlocalize:or.locale }}
            </label>
          </div>
        </div>

        <div class="d-grid">
          <button type="submit" [disabled]="!createConceptForm.valid || isUploading"
            class="mt-2 m-10 btn btn-secondary">
            <span *ngIf="isUploading" i18n>Uploading...</span>
            <span *ngIf="!isUploading" i18n="Upload Submit">Upload</span>
          </button>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-2">
            <button type="button" class="btn btn-outline-secondary btn-block" (click)="selectFile()" i18n>
              Change File
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>