import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductOptionsFormSetting } from '../product-option.type';
import { CommonModule } from '@angular/common';

import { ProductOptionUpdate } from '../../store/products.action';

@Component({
  selector: 'app-selection-product-option',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './selection-product-option.component.html',
  styleUrl: './selection-product-option.component.scss',
})
export class SelectionProductOptionComponent {
  @Input() formItem: ProductOptionsFormSetting;
  @Input() option_type: 's3' | 'color_palette' | 'enum' | 'string';
  @Input() productId: number;

  @Output() selection = new EventEmitter<ProductOptionUpdate>();

  constructor() {}

  select(value: string) {
    this.selection.emit({
      id: null,
      key: this.formItem.key,
      display_type: this.formItem.type,
      option_type: this.option_type,
      value,
    });
  }
}
