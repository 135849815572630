<div class="form-check">
  <input
    [formControl]="isSameBillingAddress"
    class="form-check-input"
    type="checkbox"
    id="isSameAddress"
  />
  <label class="form-check-label" for="isSameAddress" i18n>
    Use same address as billing address
  </label>
</div>

<div *ngIf="shippingAddress | async; let address">
  <app-address-card
    [address]="address"
    [isSelectible]="false"
    [selected]="false"
  >
  </app-address-card>
</div>

<button
  type="button"
  (click)="setShippingAddress()"
  class="btn btn-secondary col-sm-6 col-md-4 col-lg-3"
>
  {{ deliveryProvider ? modifyStr : setStr }} <span i18n="Modify or Set Billing Address">shipping address</span>
</button>
