<div
  [class]="[
    'card',
    'mb-3',
    'mx-auto',
    'rounded-3',
    selected ? 'border-dark' : 'border-secondary',
    selected ? 'border-1' : 'border-0'
  ]"
>
  <div class="card-body text-dark">
    <h5 class="card-title">{{ address.name }}</h5>
    <p class="card-text">
      {{ address.address }}<br />
      {{ address.postal_code }} {{ address.city }}<br />
      {{ address.country.name | xlocalize:address.country.locale }}
    </p>
    <p class="card-text">
      {{ address.phone_number }}<br />
      {{ address.email }}
    </p>
    <span *ngIf="address.isBusiness">
      <h5 class="card-title">{{ address.company_name }}</h5>
      <p class="card-text">
        <span i18n="company_id">ID:</span> {{ address.company_id }}<br />
        <span i18n="vat_id">VAT ID:</span> {{ address.vat_id }}
      </p>
    </span>
  </div>
  <a
    *ngIf="isSelectible"
    (click)="selectBillingAddress()"
    [class]="[
      'btn',
      selected ? 'btn-secondary' : 'btn-light',
      'stretched-link'
    ]"
    >{{ selected ? selectedStr : selectStr }}</a
  >
</div>
